import { useTranslation } from "react-i18next";

const useDb = () => {
  const { t } = useTranslation();

  const db = [
    {
      vendors: [
        /*
        {
          name: "ClubHouse Konto",
          options: [
            { option: t("sales.deposit"), color: "#fcad89", type: 1 },
            { option: t("sales.withdraw"), color: "#fc86c1", type: 2 },
            { option: t("sales.transfer"), color: "#5b45b0", type: 3 },
          ],
        },
        */
        {
          name: "Clubhouse Spill",
          options: [
            { option: t("sales.newTicket"), color: "#009e82", type: 3 },
            { option: t("sales.payout"), color: "#fc86c1", type: 5 },
            { option: t("sales.refill"), color: "#fcad89", type: 6 },
          ],
        },
        {
          name: "Win Games",
          options: [
            { option: t("sales.newTicket"), color: "#009e82", type: 4 },
            { option: t("sales.payout"), color: "#fc86c1", type: 5 },
            { option: t("sales.scan"), color: "#8a9dad", type: 8 },
          ],
        },
        {
          name: "OK Databingo",
          options: [
            { option: t("sales.newTicket"), color: "#009e82", type: 4 },
            { option: t("sales.payout"), color: "#fc86c1", type: 5 },
            { option: t("sales.refill"), color: "#fcad89", type: 6 },
            { option: t("sales.scan"), color: "#8a9dad", type: 8 },
          ],
        },
        {
          name: "EBS",
          options: [
            { option: t("sales.newTicket"), color: "#009e82", type: 24 },
            { option: t("sales.payout"), color: "#fc86c1", type: 25 },
            { option: t("sales.refill"), color: "#fcad89", type: 26 },
            { option: t("sales.scan"), color: "#8a9dad", type: 8 },
          ],
        },
        {
          name: "Norsk Tipping",
          options: [
            { option: t("sales.NTInn"), color: "#009e82", type: 13 },
            { option: t("sales.NTUt"), color: "#fc86c1", type: 14 },
            { option: t("sales.scan"), color: "#8a9dad", type: 8 },
          ],
        },

        {
          name: "Kiosk",
          options: [
            { option: t("sales.spillerkonto"), color: "#42a5f5", type: 11 },
            { option: t("sales.cardPaymentItem"), color: "#42a5f5", type: 10 },
            { option: t("sales.product"), color: "#42a5f5", type: 9 },
            { option: t("header.printLastTrans"), color: "#42a5f5", type: 12 },
          ],
        },
      ],
      kiosk: [
        {
          name: "Pølse",
          price: 20,
        },
        {
          name: "Toast",
          price: 30,
        },
        {
          name: "Brus",
          price: 30,
        },
        {
          name: "Brus Liten",
          price: 20,
        },

        {
          name: "Tobakk",
          price: 140,
        },
        {
          name: "Lighter",
          price: 30,
        },
        {
          name: "Lefse Liten",
          price: 15,
        },

        {
          name: "Melkesjokolade",
          price: 15,
        },

        {
          name: "Kvikk Lunsj",
          price: 15,
        },
        {
          name: "Rett I Koppen",
          price: 15,
        },
        {
          name: "Brus Utgått",
          price: 15,
        },
      ],
    },
  ];

  return db;
};

export default useDb;
