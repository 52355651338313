import React, { useEffect, useContext } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";

function ModalIsVerificationNeeded() {
  const { t } = useTranslation();

  const {
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    currentUserID,
    updateIsCashoutPending,
    openSessionTerminal,
    updateOpenSessionTerminal,
    logoutUser,
    updateIsFullUserEditing,

    updateShowModalIsVerificationNeeded,
    isLimitSurpassed,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    isDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    isMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserLogin,
    updateUserInfo,
    updateIsFastUserLogin,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFullUser,
    updateUserParent,
    updateUserChildren,
    updateUserSiblings,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {}, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmClose = async () => {
    updateFocusOnMainScreen(true);
    updateShowModalIsVerificationNeeded(false);
    updateIsFullUserEditing(true);
  };

  const cancel = async () => {
    updateFocusOnMainScreen(true);

    updateIsLimitSurpassed(false);
    updateIsDailyLimitSurpassed(false);
    updateIsMonthlyLimitSurpassed(false);

    logoutUser();
    updateShowUserInfo(false);
    updatePrint(false);
    resetTransactions();
    updateReceiptToPrint(false);
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateCurrentUser("");
    updateCurrentUserID("");
    updateUserLogin(false);
    updatePaymentType("");
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser("");
    updateCurrentUserID("");
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();
    updateIsVerificationNeeded(false);
    updateShowModalIsVerificationNeeded(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("sales.isVerificationNeeded")} </h1>
            <p>{t("sales.isVerificationNeededText")}</p>
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: "55px" }}
            >
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmClose();
                }}
              >
                {t("sales.confirm")}
              </button>
              <button
                className="btn-custom-no"
                onClick={() => {
                  cancel();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalIsVerificationNeeded;
