import React, { useEffect, useContext } from "react";
import {
  Person as PersonIcon,
  PhoneAndroid,
  QrCode as QrCodeIcon,
  Face6,
  Close as CloseIcon,
} from "@mui/icons-material";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";
import { IconButton } from "@mui/material";

function ModalUserFound({ users }) {
  const { t } = useTranslation();

  const {
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsFullUser,
    printer,
    updateIsAnonUserRegistration,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    updateCurrentUserID,
    updateShowModalUserFound,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleGetNewAnonHybridMember = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      var data = {
        auth_value: data.username,
        action: "searchMember",
        session: session,
      };
      socket.emit("searchMember", data);

      //Print presentation ticket
      setTimeout(() => {
        var dataPrint = {
          text: "",
          name: printer.name,
          id: printer.id,
          ip: printer.ip,
          dataToPrint: data["7 digit PIN"],
          action: "print",
        };

        //socket.emit("print", dataPrint);
      }, 2000);
    };

    socket.on("getNewAnonHybridMember", handleGetNewAnonHybridMember);

    return () => {
      socket.off("getNewAnonHybridMember", handleGetNewAnonHybridMember);
    };
  }, [socket]);

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const onClose = () => {
    updateFocusOnMainScreen(true);
    updateShowModalUserFound(false);
  };
  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    socket.emit("registerLastUsers", data);
  };

  return (
    <>
      {users.length > 0 && (
        <div id="react-confirm-alert">
          <div className="react-confirm-alert-overlay">
            <div className="react-confirm-alert">
              <div
                className="react-confirm-alert-body hide-scrollbar"
                style={{ width: "600px", maxHeight: "675px", overflow: "auto" }}
              >
                {/* Close button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1>{t("sales.usersFound")}</h1>
                  <IconButton onClick={onClose} style={{ color: "black" }}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  className="react-confirm-alert-button-group"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  {users.map((user, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignContent: "space-around",
                          width: "100%",
                          alignItems: "center",
                          marginBottom: "20px",
                          borderBottom: "2px solid black",
                        }}
                        key={index}
                      >
                        <div>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <PersonIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />
                            <span>{user.username}</span>
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <Face6
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />
                            <span>{user.firstname + " " + user.lastname}</span>
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <QrCodeIcon
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />
                            <span>{user["7 digit PIN"]}</span>
                          </p>
                          <p style={{ display: "flex", alignItems: "center" }}>
                            <PhoneAndroid
                              sx={{ fontSize: "18px", marginRight: "5px" }}
                            />
                            <span>{user.mobile}</span>
                          </p>
                        </div>

                        <button
                          className="btn-custom-confirm-user"
                          onClick={() => {
                            updateUserLogin(true);
                            updateUserInfo(user);
                            updateCurrentUser(user["7 digit PIN"]);
                            updateCurrentUserID(user["id"]);

                            if (
                              user.username.slice(0, 4) === "anon" ||
                              (user.firstname === "" && user.mobile === "")
                            ) {
                              updateIsFullUser(false);
                              updateIsHybridUser(false);
                              updateIsAnonUser(true);
                            } else if (user.username.slice(0, 6) === "hybrid") {
                              updateIsFullUser(false);
                              updateIsHybridUser(true);
                              updateIsAnonUser(false);
                            } else {
                              updateIsFullUser(true);
                              updateIsHybridUser(false);
                              updateIsAnonUser(false);
                            }

                            updateIsFastUserLogin(false);

                            var dataToRegister = {
                              action: "registerLastUsers",
                              operator: operator,
                              bingoName: hall,
                              userInfo: user,
                            };
                            registerLastUsers(dataToRegister);

                            onClose();
                          }}
                        >
                          {t("sales.selectUser")}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalUserFound;
