import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";

import useDb from "../../../data/db";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

function ModalKiosk() {
  const { t } = useTranslation();
  const db = useDb();

  const {
    updateShowUserInfo,
    updateTransactionsArray,
    updateShowKiosk,
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    parentInfo,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateShowUserInfo(false);

    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [quantity, setQuantity] = useState([
    { name: "Brus Liten", amount: 0, price: 20 },
    { name: "Rett I Koppen", amount: 0, price: 15 },
    { name: "Tobakk", amount: 0, price: 140 },
    { name: "Lighter", amount: 0, price: 30 },
    { name: "Lefse Liten", amount: 0, price: 15 },
    { name: "Pølse", amount: 0, price: 20 },
    { name: "Brus", amount: 0, price: 30 },
    { name: "Toast", amount: 0, price: 30 },
    { name: "Melkesjokolade", amount: 0, price: 15 },
    { name: "Kvikk Lunsj", amount: 0, price: 15 },
    { name: "Brus Utgått", amount: 0, price: 15 },
  ]);

  const quantityRef = useRef(quantity);
  useEffect(() => {
    quantityRef.current = quantity;
  }, [quantity]);

  const handleIncrement = (itemName) => {
    setQuantity((prevQuantity) =>
      prevQuantity.map((item) =>
        item.name === itemName ? { ...item, amount: item.amount + 1 } : item
      )
    );
  };

  const handleDecrement = (itemName) => {
    setQuantity((prevQuantity) =>
      prevQuantity.map((item) =>
        item.name === itemName && item.amount > 0
          ? { ...item, amount: item.amount - 1 }
          : item
      )
    );
  };

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleKioskTransaction = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }

      updateFocusOnMainScreen(true);
      updateShowKiosk(false);
    };
    socket.on("kioskTransaction", handleKioskTransaction);

    return () => {
      socket.off("kioskTransaction", handleKioskTransaction);
    };
  }, [socket]);

  // #region LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmTransaction = async () => {
    var transactionsToAdd = [];
    quantityRef.current.forEach((item) => {
      if (item.amount > 0) {
        for (let i = 0; i < item.amount; i++) {
          /*
          updateTransactions({
            name: item.name,
            amount: parseInt(item.price),
            type: "transaction",
          });
          */
          transactionsToAdd.push({
            name: item.name,
            amount: parseInt(item.price),
            type: "transaction",
          });
        }
      }
    });

    var dataToSend = {
      bingoName: hall,
      operator: operator,
      sessionID: session,
      provider: "Kiosk",

      user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
      userInfo: !parentInfo ? userInfo : parentInfo,

      ticketID: userInfo.id ? userInfo.id : 0,
      ticketUsername: userInfo.username ? userInfo.username : "Gjest",
      ticket7Pincode: userInfo["7 digit PIN"] ? userInfo["7 digit PIN"] : 0,

      transactions: transactionsToAdd,
    };
    updateTransactionsArray(transactionsToAdd);
    socket.emit("kioskTransaction", dataToSend);
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancelTransaction = () => {
    updateFocusOnMainScreen(true);
    updateShowKiosk(false);
  };

  // #region RENDER
  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("sales.chooseProduct")}</h1>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {db[0].kiosk.map((item, index) => {
                const currentQuantity =
                  quantity.find((q) => q.name === item.name)?.amount || 0;
                return (
                  <div key={index}>
                    <Card
                      sx={{
                        width: 190,
                        height: 190,
                        backgroundColor: "#2196f3",
                        margin: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6" color="white">
                          {item.name}
                        </Typography>
                        <Typography variant="body2" color="white">
                          {item.price} NOK
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "center" }}>
                        <IconButton
                          onClick={() => handleDecrement(item.name)}
                          size="small"
                          sx={{ color: "white" }}
                        >
                          <Remove style={{ fontSize: 50 }} />
                        </IconButton>
                        <TextField
                          value={currentQuantity}
                          inputProps={{ readOnly: true }}
                          variant="outlined"
                          size="small"
                          sx={{
                            width: "40px",
                            "& .MuiInputBase-input": {
                              textAlign: "center",
                              color: "white",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "white",
                              },
                            },
                          }}
                        />
                        <IconButton
                          onClick={() => handleIncrement(item.name)}
                          size="small"
                          sx={{ color: "white" }}
                        >
                          <Add style={{ fontSize: 50 }} />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </div>
                );
              })}
            </Box>
            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmTransaction();
                }}
              >
                {t("sales.confirm")}
              </button>
              <button
                className="btn-custom-no"
                onClick={() => {
                  cancelTransaction();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalKiosk;
