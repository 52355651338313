import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Box, TextField, Typography, Button } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import Calculator from "../../../components/Calculator/Calculator";
import { SocketContext } from "../../../context/SocketContext";
import toast, { Toaster } from "react-hot-toast";

import ClubHouseCard from "../../../assets/img/ch-card.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";

function ModalSpillerkontoPayment() {
  const { t } = useTranslation();

  const {
    updateShowSpillerkontoPayment,
    updatePayCashTemp,
    updatePayCardTemp,
    userInfo,
    updateTransactions,
    currentUser,
    hall,
    operator,
    session,
    parentInfo,
    isFullUser,
    updateRefreshUser,
    transactions,
    total,
    payCash,
    payCard,
    paySpillerkonto,
    paySpillerkontoTemp,
    updatePaySpillerkontoTemp,
    updatePaySpillerkonto,
    resetTransactions,
    updateIsCard,
    updateIsCash,
    logoutUser,
    updatePayCash,
    updatePayCard,
    updateCurrentUser,
    updateUserLogin,
  } = useContext(AppContext);

  useEffect(() => {
    updateRefreshUser(true);
    console.log("ModalSpillerkontoPayment");
  }, []);

  const { socket } = useContext(SocketContext);

  const [isWaiting, setIsWaiting] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [amountModal, setAmountModal] = useState(0);
  const amountModalRef = useRef(amountModal);
  useEffect(() => {
    amountModalRef.current = amountModal;
  }, [amountModal]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const transactionsRef = useRef(transactions);

  useEffect(() => {
    transactionsRef.current = transactions;
  }, [transactions]);

  useEffect(() => {
    updatePayCashTemp(0);
    updatePayCardTemp(0);
    updatePaySpillerkontoTemp(0);
    if (total > 0) {
      setAmountModal(total - payCash - payCard - paySpillerkonto);
      updatePaySpillerkontoTemp(total - payCash - payCard - paySpillerkonto);
    }
  }, []);

  useEffect(() => {
    if (total > 0) {
      setAmountModal(total - payCash - payCard - paySpillerkonto);
      updatePaySpillerkontoTemp(total - payCash - payCard - paySpillerkonto);
    }
  }, [total]);

  useEffect(() => {
    if (parseInt(amountModal) > total - payCash - payCard - paySpillerkonto) {
      setAmountModal(total - payCash - payCard - paySpillerkonto);
    }
    if (amountModal < 0) {
      setAmountModal(0);
    }
  }, [amountModal]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const onCloseRef = useRef(null);

  var isBusy = false;

  useEffect(() => {
    const handleCashout = (data) => {
      if (!data) {
        return;
      }
      updateRefreshUser(true);
      //updateShowSpillerkontoPayment(false);
      //updateShowSpillerkonto(false);
    };

    const handleSendPincodeTerminal = (data) => {
      var currentPincode = 0;
      if (parentInfo) {
        currentPincode = parentInfo.PIN2;
      } else {
        currentPincode = userInfo.PIN2;
      }

      if (!isBusy && currentPincode !== data.pincode) {
        isBusy = true;

        toast.error(t("error.errorCode"));
        socket.emit("activateSMS", {
          bingoName: hallRef.current,
        });

        setTimeout(() => {
          isBusy = false;
        }, 3000);
        return;
      }

      if (!isBusy && currentPincode === data.pincode) {
        setIsWaiting(false);
        setIsSuccess(true);

        socket.emit("deactivateSMS", {
          bingoName: hallRef.current,
        });

        var dataToSend = {
          playerID: !parentInfo ? userInfo.id : parentInfo.id,
          amount: parseInt(amountModalRef.current),
          usePrinter: false,
          useSMS: false,
          printBarcode: false,
          qrMessage: false,
          session: session,
          action: "cashout",
          userInfo: !parentInfo ? userInfo : parentInfo,
          cashoutType: "cash_from_wallet",
          dataToSaveTransaction: {
            operator: operator,
            bingoName: hall,
            sessionID: session,
            provider: "CH",
            description: "Betal med spillerkonto",
            amount: parseInt(amountModalRef.current),
            user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
            userInfo: !parentInfo ? userInfo : parentInfo,
            action: "newTransaction",
            ticketID: userInfo.id,
            ticketUsername: userInfo.username,
            ticket7Pincode: currentUser,
          },
        };

        socket.emit("cashoutPayment", dataToSend);

        /*
        if (onCloseRef.current) {
          onCloseRef.current();
          onCloseRef.current = null;
          updateShowSpillerkonto(false);
        }

        setTimeout(() => {
          isBusy = false;
        }, 3000);
        */
      }
    };

    socket.on("cashoutPayment", handleCashout);
    socket.on("sendPincodeTerminal", handleSendPincodeTerminal);

    return () => {
      socket.off("cashout", handleCashout);
      socket.off("sendPincodeTerminal", handleSendPincodeTerminal);
    };
  }, [socket]);

  const updateAmount = (value, isTotal) => {
    if (
      (amountModal === 0 || amountModal === "0") &&
      (value === "0" || value === "00")
    ) {
      return;
    }

    if ((amountModal === "0" || amountModal === 0) && value !== "backspace") {
      setAmountModal(value);
      updatePaySpillerkontoTemp(value);

      const tempAmount = parseInt(amountModal + value);
      if (total > 0 && tempAmount > total - payCash - payCard) {
        setAmountModal(total - payCash - payCard);
        updatePaySpillerkontoTemp(total - payCash - payCard);
      }
      return;
    }
    if ((amountModal === "0" || amountModal === 0) && value === "backspace") {
      return;
    }
    if (
      value === "backspace" &&
      amountModal.toString().length === 1 &&
      (amountModal !== "0" || amountModal !== 0)
    ) {
      setAmountModal("0");
      updatePaySpillerkontoTemp(0);
      return;
    }
    if (
      value === "backspace" &&
      amountModal.toString().length > 1 &&
      (amountModal !== "0" || amountModal !== 0)
    ) {
      setAmountModal(
        amountModal.toString().substring(0, amountModal.toString().length - 1)
      );
      updatePaySpillerkontoTemp(
        parseInt(
          amountModal.toString().substring(0, amountModal.toString().length - 1)
        )
      );

      return;
    }

    if (isTotal) {
      setAmountModal(value.toString());
      updatePaySpillerkontoTemp(value);
      return;
    }

    const tempAmount = parseInt(amountModal + value);
    if (total > 0 && tempAmount > total - payCash - payCard) {
      setAmountModal(total - payCash - payCard);
      updatePaySpillerkontoTemp(total - payCash - payCard);
      return;
    }

    setAmountModal(amountModal + value);
    updatePaySpillerkontoTemp(amountModal + value);
  };

  const handleCancelPincode = () => {
    socket.emit("deactivateSMS", {
      bingoName: hallRef.current,
    });
    setIsWaiting(false);
  };

  const handleConfirmAmount = () => {
    setIsWaiting(true);
    socket.emit("activateSMS", {
      bingoName: hallRef.current,
    });
  };

  const finishTransactionProcess = async () => {
    //Payment remaining
    if (
      total >
      parseInt(paySpillerkontoTemp) + payCash + payCard + paySpillerkonto
    ) {
      updateTransactions({
        name: t("sales.payCHTitle"),
        amount: paySpillerkontoTemp,
        type: "paymentSpillerkonto",
      });

      updatePaySpillerkonto(paySpillerkonto + paySpillerkontoTemp);
      updatePaySpillerkontoTemp(0);
      updateRefreshUser(true);
      updateShowSpillerkontoPayment(false);

      return;
    }

    //Fulfilled Total
    if (
      total ===
      parseInt(paySpillerkontoTemp) + payCash + payCard + paySpillerkonto
    ) {
      updateTransactions({
        name: t("sales.payCHTitle"),
        amount: paySpillerkontoTemp,
        type: "paymentSpillerkonto",
      });

      setTimeout(() => {
        var data = {
          operator: operator,
          bingoName: hall,
          sessionID: session,
          total: total,
          paySpillerkonto: paySpillerkontoTemp,
          payCard: payCard,
          payCash: payCash,
          exchange: 0,
          user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
          userInfo: !parentInfo ? userInfo : parentInfo,
          transactions: transactionsRef.current,
          print: 0,
          action: "newShoppingCart",
          ticketID: userInfoRef.current.id,
          ticketUsername: userInfoRef.current.username,
          ticket7Pincode: currentUser,
        };
        socket.emit("newShoppingCarts", data);
        resetTransactions();
        updateIsCard(false);
        updateIsCash(false);
        updateCurrentUser("");
        updateUserLogin(false);
        logoutUser();
        updatePayCard(0);
        updatePayCashTemp(0);
        updatePayCash(0);
        updatePayCardTemp(0);
        updatePaySpillerkonto(0);
        updatePaySpillerkontoTemp(0);
        updateShowSpillerkontoPayment(false);
      }, 500);

      return;
    }
  };

  return (
    <div className="modal-overlay">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: "9999999 !important",
            fontSize: "24px",
          },
        }}
        containerStyle={{
          zIndex: 99999999, // Ensure this value is higher than the modal's z-index
        }}
      />
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            className="custom-modal-2"
            style={{
              background: "white",
              borderRadius: "40px",
              width: "1165px",
              height: "839px",
            }}
          >
            {isWaiting && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px",
                }}
              >
                <Grid
                  container
                  sx={{
                    height: "100%",
                    width: "100%",
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#464646",
                      }}
                    >
                      {t("sales.pincodeTitle")}
                    </h1>
                    <p>{t("sales.pincodeText1")}</p>

                    <button
                      onClick={() => {
                        handleCancelPincode();
                      }}
                      className="btn-custom-no"
                    >
                      {t("sales.cancel")}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {isFail && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px",
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: "100%",
                    width: "100%",
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#464646",
                      }}
                    >
                      {t("sales.transactionFail")}
                    </h1>
                    <p>{/* Message here, if any */}</p>

                    <button
                      onClick={() => {
                        setIsFail(false);
                        setIsWaiting(false);
                        setIsSuccess(false);

                        updatePaySpillerkontoTemp(0);
                        updateShowSpillerkontoPayment(false);
                      }}
                      className="btn-custom-no"
                    >
                      {t("sales.backToPayment")}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {isSuccess && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px",
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: "100%",
                    width: "100%",
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#464646",
                      }}
                    >
                      {t("sales.transactionDone")}
                    </h1>
                    <p>{/* Message here, if any */}</p>

                    <button
                      onClick={async () => {
                        await finishTransactionProcess();

                        setTimeout(() => {
                          setIsSuccess(false);
                          updateShowSpillerkontoPayment(false);
                        }, 300);
                      }}
                      className="btn-custom-yes"
                    >
                      {t("sales.confirm")}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {!isWaiting && !isFail && !isSuccess && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px",
                }}
              >
                {/*MAIN CONTAINER*/}
                <Grid
                  container
                  sx={{
                    height: "100%",
                    width: "100%",
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <>
                    {/*LEFT CONTAINER, title, logo, inputs, shortcuts*/}
                    <Grid
                      item
                      xs={6}
                      sx={{
                        padding: "10px",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Grid
                        container
                        flexDirection="column"
                        sx={{
                          height: "100%",
                          width: "100%",
                          marginTop: 0,
                          marginLeft: 0,
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        {/* TITLE */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            height: "75px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: "flex-start",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-70px",
                          }}
                        >
                          <h2
                            style={{
                              color: "#464646",
                            }}
                          >
                            {t("sales.payCHTitle")}
                          </h2>
                        </Grid>

                        {/* CARD LOGO */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            height: "150px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: "flex-start",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "30px",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: "150px",
                              width: "auto",
                              paddingTop: 0,
                            }}
                            alt=""
                            src={ClubHouseCard}
                          />
                        </Grid>

                        {/* INPUTS */}
                        <Grid
                          container
                          flexDirection="row"
                          sx={{
                            height: "calc(100% - 605px)",
                            width: "100%",
                            alignContent: "space-around",
                          }}
                        >
                          {/*Input*/}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: "10px",
                              height: "100px",
                              width: "100%",
                              marginTop: 0,
                              marginLeft: 0,
                              justifyContent: "center",
                              alignContent: "center",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              container
                              flexDirection="row"
                              sx={{
                                height: "100%",
                                width: "100%",
                                marginTop: 0,
                                marginLeft: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  padding: "10px",
                                  display: "flex", // Use flex display for alignment
                                  alignItems: "center", // Vertically center the contents
                                  justifyContent: "flex-start", // Horizontally center the contents
                                  height: "75px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {" "}
                                  {/* Container for icon and text */}
                                  <PaymentIcon
                                    sx={{
                                      fontSize: "45px",
                                      marginRight: "10px", // Adjust spacing between icon and text
                                      color: "#464646",
                                    }}
                                  />
                                  <div>
                                    {" "}
                                    {/* Container for the text */}
                                    <Typography
                                      sx={{
                                        fontSize: "19px",
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: "#464646",
                                      }}
                                    >
                                      {t("sales.amount")}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "15px", // Smaller font size for the second line
                                        color: "#464646",
                                      }}
                                    >
                                      {t("sales.withdrawSubtitle")}
                                    </Typography>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  marginTop: 0,
                                  padding: "10px",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <TextField
                                  autoComplete="off"
                                  value={
                                    amountModal === 0 || amountModal === "0"
                                      ? ""
                                      : amountModal
                                  }
                                  sx={{
                                    "& label": {
                                      position: "relative !important",
                                      fontSize: "22px !important",
                                      color: "#464646 !important",
                                    },
                                    "& label.Mui-focused": {
                                      position: "relative !important",
                                      color: "#464646 !important",
                                    },
                                    "& input": {
                                      color: "#464646 !important",
                                      border: "3px solid #464646",
                                      borderRadius: "4px",
                                      height: "50px !important",
                                      width: "275px !important",
                                      fontSize: "40px !important",
                                      textAlign: "center",
                                    },
                                    "& input.Mui-focused": {
                                      color: "#464646 !important",
                                      border: "3px solid #464646",
                                      borderRadius: "4px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: "transparent", // Change the border color or set to 'transparent'
                                      },
                                    },
                                    "& .MuiInputBase-input": {
                                      caretColor: "transparent", // Makes the cursor transparent
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/*SHORTCUTS*/}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            height: "335px",
                            width: "100%",
                            marginLeft: 0,
                            justifyContent: "center",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginTop: "10px",
                          }}
                        >
                          <h2
                            style={{
                              width: "100%",
                              textAlign: "left",
                              color: "#464646",
                            }}
                          >
                            {t("sales.shortcuts")}
                          </h2>
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: "100%",
                              width: "100%",
                              marginTop: 0,
                              marginLeft: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                border: "2px solid black",
                                width: "100%",
                                maxWidth: "100% !important",
                                height: "100%",
                                backgroundColor: "#f0f0f0",
                              }}
                            >
                              <Grid
                                container
                                flexDirection="row"
                                sx={{
                                  height: "100%",
                                  width: "100%",
                                  marginTop: 0,
                                  marginLeft: 0,
                                  display: "flex",
                                  alignContent: "space-around",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: "100px",
                                      width: "100px",
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        backgroundColor: "#889cab",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                      onClick={() => {
                                        setAmountModal(10);
                                        updatePaySpillerkontoTemp(10);
                                        if (
                                          total > 0 &&
                                          10 > total - payCash - payCard
                                        ) {
                                          setAmountModal(
                                            total - payCash - payCard
                                          );
                                          updatePaySpillerkontoTemp(
                                            total - payCash - payCard
                                          );
                                        }
                                      }}
                                    >
                                      10
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: "100px",
                                      width: "100px",
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        backgroundColor: "#889cab",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                      onClick={() => {
                                        setAmountModal(20);
                                        updatePaySpillerkontoTemp(20);
                                        if (
                                          total > 0 &&
                                          20 > total - payCash - payCard
                                        ) {
                                          setAmountModal(
                                            total - payCash - payCard
                                          );
                                          updatePaySpillerkontoTemp(
                                            total - payCash - payCard
                                          );
                                        }
                                      }}
                                    >
                                      20
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: "100px",
                                      width: "100px",
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        backgroundColor: "#889cab",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                      onClick={() => {
                                        setAmountModal(50);
                                        updatePaySpillerkontoTemp(50);
                                        if (
                                          total > 0 &&
                                          50 > total - payCash - payCard
                                        ) {
                                          setAmountModal(
                                            total - payCash - payCard
                                          );
                                          updatePaySpillerkontoTemp(
                                            total - payCash - payCard
                                          );
                                        }
                                      }}
                                    >
                                      50
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: "100px",
                                      width: "100px",
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        backgroundColor: "#889cab",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                      onClick={() => {
                                        setAmountModal(100);
                                        updatePaySpillerkontoTemp(100);
                                        if (
                                          total > 0 &&
                                          100 > total - payCash - payCard
                                        ) {
                                          setAmountModal(
                                            total - payCash - payCard
                                          );
                                          updatePaySpillerkontoTemp(
                                            total - payCash - payCard
                                          );
                                        }
                                      }}
                                    >
                                      100
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: "100px",
                                      width: "100px",
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        backgroundColor: "#889cab",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                      onClick={() => {
                                        setAmountModal(200);
                                        updatePaySpillerkontoTemp(200);
                                        if (
                                          total > 0 &&
                                          200 > total - payCash - payCard
                                        ) {
                                          setAmountModal(
                                            total - payCash - payCard
                                          );
                                          updatePaySpillerkontoTemp(
                                            total - payCash - payCard
                                          );
                                        }
                                      }}
                                    >
                                      200
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: "100px",
                                      width: "100px",
                                      display: "flex",
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: "100%",
                                        width: "100%",
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        backgroundColor: "#889cab",
                                        color: "white",
                                        marginTop: "0px",
                                      }}
                                      onClick={() => {
                                        setAmountModal(500);
                                        updatePaySpillerkontoTemp(500);
                                        if (
                                          total > 0 &&
                                          500 > total - payCash - payCard
                                        ) {
                                          setAmountModal(
                                            total - payCash - payCard
                                          );
                                          updatePaySpillerkontoTemp(
                                            total - payCash - payCard
                                          );
                                        }
                                      }}
                                    >
                                      500
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/*RIGHT CONTAINER, saldo, calculator, buttons*/}
                    <Grid
                      item
                      xs={6}
                      sx={{
                        padding: "10px",
                      }}
                    >
                      {/* SUBTITLE SALDO */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          padding: "10px",
                          height: "35px",
                          width: "100%",
                          marginTop: 0,
                          marginLeft: 0,
                          justifyContent: "flex-start",
                          alignContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "#464646",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          {!isFullUser
                            ? t("sales.saldoUserAccount") +
                              " " +
                              parentInfo.balances.wallet
                            : t("sales.saldoUserAccount") +
                              " " +
                              userInfo.balances.wallet}
                        </p>
                      </Grid>

                      {/* CALCULATOR */}
                      <Calculator
                        updateAmount={updateAmount}
                        isColorCard={true}
                      />

                      {/* BUTTONS */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          width: "100%",
                          maxWidth: "100% !important",
                          height: "100%",
                        }}
                      >
                        {/*Buttons container*/}
                        <Grid
                          container
                          flexDirection="row"
                          sx={{
                            height: "125px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            padding: "10px",
                          }}
                        >
                          {/*CONFIRM*/}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                width: "200px",
                                height: "90px",
                                backgroundColor:
                                  amountModal != 0 ? "#009f83" : "grey",
                                opacity: amountModal != 0 ? "1" : "0.25",
                                color: "white",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}
                              onClick={() => {
                                if (parseInt(amountModal) == 0) {
                                  return;
                                }
                                handleConfirmAmount();
                              }}
                            >
                              {t("sales.confirm")}
                            </Button>
                          </Grid>

                          {/*CANCEL*/}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                width: "200px",
                                height: "90px",
                                backgroundColor: "#fc86c1",
                                color: "white",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}
                              onClick={() => {
                                updatePaySpillerkontoTemp(0);
                                updateShowSpillerkontoPayment(false);
                              }}
                            >
                              {t("sales.cancel")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSpillerkontoPayment;
