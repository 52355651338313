export const countriesDataNO = [
  {
    value: "Afghanistan",
    label: "Afghanistan",
  },
  {
    value: "Aland Islands",
    label: "Ålandøyene",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Algeria",
    label: "Algerie",
  },
  {
    value: "American Samoa",
    label: "Amerikansk Samoa",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
  },
  {
    value: "Antarctica",
    label: "Antarktis",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua og Barbuda",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "Armenia",
    label: "Armenia",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Austria",
    label: "Østerrike",
  },
  {
    value: "Azerbaijan",
    label: "Aserbajdsjan",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Belarus",
    label: "Hviterussland",
  },
  {
    value: "Belgium",
    label: "Belgia",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
  },
  {
    value: "Bosnia-Herzegovina",
    label: "Bosnia-Hercegovina",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "Bouvet Island",
    label: "Bouvetøya",
  },
  {
    value: "Brazil",
    label: "Brasil",
  },
  {
    value: "British Indian Ocean Territory",
    label: "Britisk territorium i Indiahavet",
  },
  {
    value: "Brunei Darussalam",
    label: "Brunei",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Cambodia",
    label: "Kambodsja",
  },
  {
    value: "Cameroon",
    label: "Kamerun",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
  },
  {
    value: "Cayman Islands",
    label: "Caymanøyene",
  },
  {
    value: "Central African Republic",
    label: "Den sentralafrikanske republikk",
  },
  {
    value: "Chad",
    label: "Tsjad",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "China",
    label: "Kina",
  },
  {
    value: "Christmas Island",
    label: "Christmasøya",
  },
  {
    value: "Cocos Islands",
    label: "Cocos Islands",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Comoros",
    label: "Komorene",
  },
  {
    value: "Congo",
    label: "Kongo",
  },
  {
    value: "Congo, Democratic People's Republic",
    label: "Congo, Democratic People's Republic",
  },
  {
    value: "Cook Islands",
    label: "Cookøyene",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
  },
  {
    value: "Croatia",
    label: "Kroatia",
  },
  {
    value: "Cuba",
    label: "Cuba",
  },
  {
    value: "Cyprus",
    label: "Kypros",
  },
  {
    value: "Czech Republic",
    label: "Tsjekkia",
  },
  {
    value: "Denmark",
    label: "Danmark",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    label: "Den dominikanske republikk",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "England",
    label: "England",
  },
  {
    value: "Equatorial Guinea",
    label: "Ekvatorial-Guinea",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    label: "Estland",
  },
  {
    value: "Ethiopia",
    label: "Etiopia",
  },
  {
    value: "European Union",
    label: "European Union",
  },
  {
    value: "Falkland Islands",
    label: "Falkland Islands",
  },
  {
    value: "Faroe Islands",
    label: "Færøyene",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "Former USSR",
    label: "Former USSR",
  },
  {
    value: "France",
    label: "Frankrike",
  },
  {
    value: "France. Metropolitan",
    label: "France. Metropolitan",
  },
  {
    value: "French Guyana",
    label: "French Guyana",
  },
  {
    value: "French Southern Territories",
    label: "De franske sørterritorier",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Gambia",
    label: "Gambia",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Germany",
    label: "Tyskland",
  },
  {
    value: "Ghana",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
  },
  {
    value: "Great Britain",
    label: "Great Britain",
  },
  {
    value: "Greece",
    label: "Hellas",
  },
  {
    value: "Greenland",
    label: "Grønland",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Guadeloupe (French)",
    label: "Guadeloupe (French)",
  },
  {
    value: "Guam (USA)",
    label: "Guam (USA)",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
  },
  {
    value: "Guinea",
    label: "Guinea",
  },
  {
    value: "Guinea Bissau",
    label: "Guinea Bissau",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Heard & McDonald Islands",
    label: "Heard & McDonald Islands",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    label: "Hongkong",
  },
  {
    value: "Hungary",
    label: "Ungarn",
  },
  {
    value: "Iceland",
    label: "Island",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Iran",
    label: "Iran",
  },
  {
    value: "Iraq",
    label: "Irak",
  },
  {
    value: "Ireland",
    label: "Irland",
  },
  {
    value: "Isle of Man",
    label: "Man",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Italy",
    label: "Italia",
  },
  {
    value: "Ivory Coast (Cote D'Ivoire)",
    label: "Ivory Coast (Cote D'Ivoire)",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
  {
    value: "Jordan",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    label: "Kasakhstan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    label: "Kirgisistan",
  },
  {
    value: "Laos",
    label: "Laos",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    label: "Libanon",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    label: "Liberia",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    label: "Litauen",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
  },
  {
    value: "Macau",
    label: "Macau",
  },
  {
    value: "Macedonia",
    label: "Macedonia",
  },
  {
    value: "Madagascar",
    label: "Madagaskar",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    label: "Maldivene",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    label: "Marshalløyene",
  },
  {
    value: "Martinique (French)",
    label: "Martinique (French)",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Micronesia",
    label: "Micronesia",
  },
  {
    value: "Moldavia",
    label: "Moldavia",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Morocco",
    label: "Marokko",
  },
  {
    value: "Mozambique",
    label: "Mosambik",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
  {
    value: "Namibia",
    label: "Namibia",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    label: "Nederland",
  },
  {
    value: "Netherlands Antilles",
    label: "Netherlands Antilles",
  },
  {
    value: "New Caledonia (French)",
    label: "New Caledonia (French)",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    label: "Niger",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Niue",
    label: "Niue",
  },
  {
    value: "Norfolk Island",
    label: "Norfolkøya",
  },
  {
    value: "North Korea",
    label: "North Korea",
  },
  {
    value: "Northern Ireland",
    label: "Northern Ireland",
  },
  {
    value: "Northern Mariana Islands",
    label: "Nord-Marianene",
  },
  {
    value: "Norway",
    label: "Norge",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Palestinian Territory",
    label: "Palestinian Territory",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    label: "Papua Ny-Guinea",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "Philippines",
    label: "Filippinene",
  },
  {
    value: "Pitcairn Island",
    label: "Pitcairn Island",
  },
  {
    value: "Poland",
    label: "Polen",
  },
  {
    value: "Polynesia (French)",
    label: "Polynesia (French)",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Private IP",
    label: "Private IP",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Reunion Island",
    label: "Reunion Island",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Russian Federation",
    label: "Russland",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
  },
  {
    value: "Saint Helena",
    label: "Saint Helena",
  },
  {
    value: "Saint Kitts & Nevis Anguilla",
    label: "Saint Kitts & Nevis Anguilla",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint-Pierre og Miquelon",
  },
  {
    value: "Saint Tome (Sao Tome) and Principe",
    label: "Saint Tome (Sao Tome) and Principe",
  },
  {
    value: "Saint Vincent & Grenadines",
    label: "Saint Vincent & Grenadines",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi-Arabia",
  },
  {
    value: "Scotland",
    label: "Scotland",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "Seychelles",
    label: "Seychellene",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Slovak Republic",
    label: "Slovak Republic",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    label: "Salomonøyene",
  },
  {
    value: "Somalia",
    label: "Somalia",
  },
  {
    value: "South Africa",
    label: "Sør-Afrika",
  },
  {
    value: "South Georgia & Sandwich Islands",
    label: "South Georgia & Sandwich Islands",
  },
  {
    value: "South Korea",
    label: "South Korea",
  },
  {
    value: "Spain",
    label: "Spania",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    label: "Sudan",
  },
  {
    value: "Suriname",
    label: "Surinam",
  },
  {
    value: "Svalbard and Jan Mayen Islands",
    label: "Svalbard and Jan Mayen Islands",
  },
  {
    value: "Swaziland",
    label: "Swaziland",
  },
  {
    value: "Sweden",
    label: "Sverige",
  },
  {
    value: "Switzerland",
    label: "Sveits",
  },
  {
    value: "Syria",
    label: "Syria",
  },
  {
    value: "Tadjikistan",
    label: "Tadjikistan",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Tibet",
    label: "Tibet",
  },
  {
    value: "Timor-Leste",
    label: "Øst-Timor",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Tokelau",
    label: "Tokelau",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad og Tobago",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    label: "Tyrkia",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks- og Caicosøyene",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    label: "Ukraina",
  },
  {
    value: "United Arab Emirates",
    label: "De forente arabiske emirater",
  },
  {
    value: "United Kingdom",
    label: "Storbritannia",
  },
  {
    value: "United Nations",
    label: "United Nations",
  },
  {
    value: "United States",
    label: "USA",
  },
  {
    value: "United States Minor Outlying Islands",
    label: "USAs ytre småøyer",
  },
  {
    value: "Unknown",
    label: "Unknown",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    label: "Usbekistan",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Vatican City State",
    label: "Vatican City State",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Virgin Islands (British)",
    label: "Virgin Islands (British)",
  },
  {
    value: "Virgin Islands (USA)",
    label: "Virgin Islands (USA)",
  },
  {
    value: "Wales",
    label: "Wales",
  },
  {
    value: "Wallis and Futuna Islands",
    label: "Wallis and Futuna Islands",
  },
  {
    value: "Western Sahara",
    label: "Vest-Sahara",
  },
  {
    value: "Yemen",
    label: "Jemen",
  },
  {
    value: "Yugoslavia",
    label: "Yugoslavia",
  },
  {
    value: "Zaire",
    label: "Zaire",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
];
