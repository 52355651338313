import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";
import MoneyIcon from "@mui/icons-material/Money";
import Box from "@mui/material/Box";

import ClubHouseLogo from "../../../assets/img/clubhouse_logo.png";

function ModalUserInfo() {
  const { t } = useTranslation();

  const {
    updateTransactionsArray,
    updateShowUserInfo,
    parentInfo,

    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  const [isTerminalBusy, setIsTerminalBusy] = useState(false);

  useEffect(() => {
    const handleCashout = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        setIsTerminalBusy(false);
        //deleteTransactions(transactions[transactions.length - 1]);
        return;
      }

      updateTransactionsArray([
        {
          name: "Utbetaling - CH",
          //amount: parseInt(amount),
          amount: data.amount * -1,
          type: "transaction",
          ticketData: data,
        },
      ]);

      setTimeout(() => {
        updateShowUserInfo(false);
        setIsTerminalBusy(false);
        updateShowUserInfo(false);
        updateFocusOnMainScreen(true);
      }, 300);
    };

    socket.on("cashoutTicket", handleCashout);

    return () => {
      socket.off("cashoutTicket", handleCashout);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handlePayOut = async () => {
    setIsTerminalBusy(true);
    if (isTerminalBusy) {
      return;
    }
    var dataNewTicket = {
      name: "Utbetaling - CH",
      amount: parseInt(userInfo.balances.wallet),
      type: "transaction",
    };

    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: "CH",
      description: "Utbetaling",
      amount: parseInt(userInfo.balances.wallet),
      user7Pincode: parentInfo["7 digit PIN"],
      userInfo: parentInfo,
      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      playerID: userInfo.id,
      cashoutType: "cash_from_wallet",
      ticketID: userInfo.id,
      ticketUsername: userInfo.username,
      ticket7Pincode: currentUser,

      action: "cashoutTicket",
    };

    data.transactions.push(dataNewTicket);
    socket.emit("cashoutTicket", data);

    /*
    if (!openSessionTerminal.isPowerBingo) {
      updateOpenSessionTerminal(false);
      updateFocusOnMainScreen(true);

      resetTransactions();
      updateIsCard(false);
      updateIsCash(false);
      updateTotal(0);
      updatePayCard(0);
      updatePayCash(0);
      updateChange(0);
      updateCurrentUser("");
      updateCurrentUserID("");
      updateUserLogin(false);
      updatePaymentType("");
      updateIsPaymentOpen(false);
      updateIsSellerOpen(false);
      logoutUser();
      updateCurrentUser("");
      updateCurrentUserID("");
      updateUserLogin(false);
      updateUserInfo({});
      updateIsFastUserLogin(false);
      updateIsAnonUser(false);
      updateIsHybridUser(false);
      updateIsFullUser(false);
      updateUserParent(false);
      updateUserChildren([]);
      updateUserSiblings([]);
      resetTicketsCH();

      return;
    }

    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: "cashout - cash_from_wallet - terminal",
      description: "cashout - cash_from_wallet - terminal",
      //amount: parseInt(amount),
      amount: 200,
      user7Pincode: currentUser,
      userInfo: userInfo,
      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      playerID: userInfo.id,
      cashoutType: "cash_from_wallet",
      ticketID: userInfo.id,
      ticketUsername: userInfo.username,
      ticket7Pincode: currentUser,

      action: "newSignature",
    };

    console.log("signature", data);
    socket.emit("newSignature", data);
    */
  };

  return (
    <div
      style={{
        width: "100%",
        height: "200px",
        position: "absolute",
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <div
        style={{
          width: "800px",
          height: "200px",
          background: "white",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          zIndex: "9999998",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: "100px",
              width: "auto",
              paddingTop: 0,
            }}
            alt=""
            src={ClubHouseLogo}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black" }}>{t("sales.ticket7Pincode")}</p>
          <span
            style={{
              fontSize: "22px",
              color: "#42a5f5",
              fontWeight: "bold",
            }}
          >
            {currentUser}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "black" }}>{t("sales.currentSaldo")}</p>
          <span
            style={{
              fontSize: "22px",
              color: "#42a5f5",
              fontWeight: "bold",
            }}
          >
            {userInfo.balances.wallet} NOK
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="react-confirm-alert-button-group">
            <button
              className="btn-custom-no"
              onClick={() => {
                if (userInfo.balances.wallet < 1) {
                  return;
                }
                handlePayOut();
              }}
              style={{
                backgroundColor: "black !important",
                opacity: userInfo.balances.wallet < 1 ? "0.3" : 1,
              }}
            >
              <MoneyIcon />
              {t("sales.payout")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalUserInfo;
