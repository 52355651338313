export const countriesDataEN = [
  {
    value: "Afghanistan",
    label: "Afghanistan",
  },
  {
    value: "Aland Islands",
    label: "Aland Islands",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Algeria",
    label: "Algeria",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
  },
  {
    value: "Antarctica",
    label: "Antarctica",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "Armenia",
    label: "Armenia",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Belarus",
    label: "Belarus",
  },
  {
    value: "Belgium",
    label: "Belgium",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
  },
  {
    value: "Bosnia-Herzegovina",
    label: "Bosnia-Herzegovina",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "Bouvet Island",
    label: "Bouvet Island",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  {
    value: "Brunei Darussalam",
    label: "Brunei Darussalam",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
  },
  {
    value: "Cameroon",
    label: "Cameroon",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
  },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
  },
  {
    value: "Central African Republic",
    label: "Central African Republic",
  },
  {
    value: "Chad",
    label: "Chad",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Christmas Island",
    label: "Christmas Island",
  },
  {
    value: "Cocos Islands",
    label: "Cocos Islands",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Comoros",
    label: "Comoros",
  },
  {
    value: "Congo",
    label: "Congo",
  },
  {
    value: "Congo, Democratic People's Republic",
    label: "Congo, Democratic People's Republic",
  },
  {
    value: "Cook Islands",
    label: "Cook Islands",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Cuba",
    label: "Cuba",
  },
  {
    value: "Cyprus",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    label: "Czech Republic",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "England",
    label: "England",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    label: "Estonia",
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
  },
  {
    value: "European Union",
    label: "European Union",
  },
  {
    value: "Falkland Islands",
    label: "Falkland Islands",
  },
  {
    value: "Faroe Islands",
    label: "Faroe Islands",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "Former USSR",
    label: "Former USSR",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "France. Metropolitan",
    label: "France. Metropolitan",
  },
  {
    value: "French Guyana",
    label: "French Guyana",
  },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Gambia",
    label: "Gambia",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Ghana",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
  },
  {
    value: "Great Britain",
    label: "Great Britain",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "Greenland",
    label: "Greenland",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Guadeloupe (French)",
    label: "Guadeloupe (French)",
  },
  {
    value: "Guam (USA)",
    label: "Guam (USA)",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
  },
  {
    value: "Guinea",
    label: "Guinea",
  },
  {
    value: "Guinea Bissau",
    label: "Guinea Bissau",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Heard & McDonald Islands",
    label: "Heard & McDonald Islands",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "Iceland",
    label: "Iceland",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Iran",
    label: "Iran",
  },
  {
    value: "Iraq",
    label: "Iraq",
  },
  {
    value: "Ireland",
    label: "Ireland",
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Ivory Coast (Cote D'Ivoire)",
    label: "Ivory Coast (Cote D'Ivoire)",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
  {
    value: "Jordan",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
  },
  {
    value: "Laos",
    label: "Laos",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    label: "Liberia",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
  },
  {
    value: "Macau",
    label: "Macau",
  },
  {
    value: "Macedonia",
    label: "Macedonia",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    label: "Maldives",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Martinique (French)",
    label: "Martinique (French)",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Micronesia",
    label: "Micronesia",
  },
  {
    value: "Moldavia",
    label: "Moldavia",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Morocco",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
  {
    value: "Namibia",
    label: "Namibia",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
  },
  {
    value: "Netherlands Antilles",
    label: "Netherlands Antilles",
  },
  {
    value: "New Caledonia (French)",
    label: "New Caledonia (French)",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    label: "Niger",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Niue",
    label: "Niue",
  },
  {
    value: "Norfolk Island",
    label: "Norfolk Island",
  },
  {
    value: "North Korea",
    label: "North Korea",
  },
  {
    value: "Northern Ireland",
    label: "Northern Ireland",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Palestinian Territory",
    label: "Palestinian Territory",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Pitcairn Island",
    label: "Pitcairn Island",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Polynesia (French)",
    label: "Polynesia (French)",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Private IP",
    label: "Private IP",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Reunion Island",
    label: "Reunion Island",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Russian Federation",
    label: "Russian Federation",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
  },
  {
    value: "Saint Helena",
    label: "Saint Helena",
  },
  {
    value: "Saint Kitts & Nevis Anguilla",
    label: "Saint Kitts & Nevis Anguilla",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Saint Tome (Sao Tome) and Principe",
    label: "Saint Tome (Sao Tome) and Principe",
  },
  {
    value: "Saint Vincent & Grenadines",
    label: "Saint Vincent & Grenadines",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
  },
  {
    value: "Scotland",
    label: "Scotland",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Slovak Republic",
    label: "Slovak Republic",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
  },
  {
    value: "Somalia",
    label: "Somalia",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "South Georgia & Sandwich Islands",
    label: "South Georgia & Sandwich Islands",
  },
  {
    value: "South Korea",
    label: "South Korea",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    label: "Sudan",
  },
  {
    value: "Suriname",
    label: "Suriname",
  },
  {
    value: "Svalbard and Jan Mayen Islands",
    label: "Svalbard and Jan Mayen Islands",
  },
  {
    value: "Swaziland",
    label: "Swaziland",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Syria",
    label: "Syria",
  },
  {
    value: "Tadjikistan",
    label: "Tadjikistan",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Tibet",
    label: "Tibet",
  },
  {
    value: "Timor-Leste",
    label: "Timor-Leste",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Tokelau",
    label: "Tokelau",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    label: "Ukraine",
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "United Nations",
    label: "United Nations",
  },
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "Unknown",
    label: "Unknown",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Vatican City State",
    label: "Vatican City State",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Virgin Islands (British)",
    label: "Virgin Islands (British)",
  },
  {
    value: "Virgin Islands (USA)",
    label: "Virgin Islands (USA)",
  },
  {
    value: "Wales",
    label: "Wales",
  },
  {
    value: "Wallis and Futuna Islands",
    label: "Wallis and Futuna Islands",
  },
  {
    value: "Western Sahara",
    label: "Western Sahara",
  },
  {
    value: "Yemen",
    label: "Yemen",
  },
  {
    value: "Yugoslavia",
    label: "Yugoslavia",
  },
  {
    value: "Zaire",
    label: "Zaire",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
];
