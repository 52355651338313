import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";

function ModalDifferentParent() {
  const { t } = useTranslation();

  const {
    adoptedTicket,
    parentInfo,
    updateAdoptedTicket,
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    currentUserID,
    updateIsCashoutPending,
    openSessionTerminal,
    updateOpenSessionTerminal,
    logoutUser,
    updateIsFullUserEditing,

    updateShowModalDifferentParent,
    isLimitSurpassed,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    isDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    isMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserLogin,
    updateUserInfo,
    updateIsFastUserLogin,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFullUser,
    updateUserParent,
    updateUserChildren,
    updateUserSiblings,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
    updateTransactionsArray,
  } = useContext(AppContext);

  const [isTerminalBusy, setIsTerminalBusy] = useState(false);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleCashout = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        setIsTerminalBusy(false);
        //deleteTransactions(transactions[transactions.length - 1]);
        return;
      }

      console.log("data cashout", data);

      updateTransactionsArray([
        {
          name: "Utbetaling - CH",
          //amount: parseInt(amount),
          amount: data.amount * -1,
          type: "transaction",
          ticketData: data,
        },
      ]);

      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      setTimeout(() => {
        setIsTerminalBusy(false);
        updateFocusOnMainScreen(true);
        cancel();
      }, 300);
    };

    socket.on("cashoutTicketAdopted", handleCashout);

    return () => {
      socket.off("cashoutTicketAdopted", handleCashout);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handlePayout = async () => {
    if (isTerminalBusy) {
      return;
    }
    updateFocusOnMainScreen(true);

    setIsTerminalBusy(true);

    var dataNewTicket = {
      name: "Utbetaling - CH",
      amount: parseInt(adoptedTicket.ticket.balances.wallet),
      type: "transaction",
    };

    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: "CH",
      description: "Utbetaling",
      amount: parseInt(adoptedTicket.ticket.balances.wallet),
      user7Pincode: adoptedTicket.parent.user7pincode,
      userInfo: adoptedTicket.parent.userInfo,
      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      playerID: adoptedTicket.ticket.id,
      cashoutType: "cash_from_wallet",
      ticketID: adoptedTicket.ticket.id,
      ticketUsername: adoptedTicket.ticket.username,
      ticket7Pincode: adoptedTicket.ticket["7 digit PIN"],
      adoptedParent: parentInfo ? parentInfo : userInfo,

      action: "cashoutTicketAdopted",
    };

    console.log("data", data);

    data.transactions.push(dataNewTicket);
    socket.emit("cashoutTicketAdopted", data);
  };

  const cancel = async () => {
    updateOpenSessionTerminal(false);
    updateAdoptedTicket({});
    updateFocusOnMainScreen(true);
    updateShowModalDifferentParent(false);
  };

  const getTerminalNumber = (ip) => {
    const parts = ip.split(".");
    const lastPart = parts[parts.length - 1];
    return lastPart;
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("sales.ticketWithDifferentParentTitle")} </h1>
            <p>{t("sales.ticketWithDifferentParentText")}</p>
            <p>
              {t("sales.currentSaldo")}:{" "}
              <span
                style={{
                  fontSize: "22px",
                  color: "#42a5f5",
                  fontWeight: "bold",
                }}
              >
                {adoptedTicket.ticket.balances.wallet + " kr."}
              </span>
            </p>

            {openSessionTerminal && (
              <p>
                {t("sales.openSessionText1")}
                <span
                  style={{
                    fontSize: "22px",
                    color: "#42a5f5",
                    fontWeight: "bold",
                  }}
                >
                  {getTerminalNumber(openSessionTerminal.terminalIP)}
                </span>
                {t("sales.openSessionText2")}
                <span
                  style={{
                    fontSize: "22px",
                    color: "#42a5f5",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {openSessionTerminal.saldo}
                </span>
                {" NOK"}
              </p>
            )}

            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: "55px" }}
            >
              {!openSessionTerminal && (
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handlePayout();
                  }}
                >
                  {t("sales.confirm")}
                </button>
              )}

              <button
                className="btn-custom-no"
                onClick={() => {
                  cancel();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDifferentParent;
