import React from "react";
import { Typography, Grid, Divider, Avatar, Box } from "@mui/material";

import Logo from "../../../assets/img/clubhouse_logo.png";
import LogoOK from "../../../assets/img/okbingo.png";
import LogoWin from "../../../assets/img/wingames.png";
import LogoNT from "../../../assets/img/norksTipping.png";
import LogoKiosk from "../../../assets/img/kiosk.png";
import LogoEBS from "../../../assets/img/ebs.png";

function VendorItemHeader({ vendor }) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        height: "100px",
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
      }}
    >
      {/*HEADER CONTAINER*/}

      <Grid
        container
        flexDirection="row"
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
        }}
      >
        {/*HEADER ITEM IMAGE*/}

        <Grid
          item
          xs={5}
          flexDirection="row"
          sx={{
            height: "75px",
            width: "100%",
            marginTop: "10px",
            marginLeft: 0,
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "rgba(0,0,0,0.1)",
              height: "100%",
              width: "100%",
              aspectRatio: "1",
              padding: 0,
              marginLeft: "20px",
            }}
            variant="rounded"
          >
            <Box
              component="img"
              sx={
                (vendor.name === "Norsk Tipping" && {
                  width: "auto",
                  height: "100% !important",
                  paddingTop: 0,
                }) ||
                (vendor.name === "EBS" && {
                  width: "auto",
                  height: "85%",
                  paddingTop: 0,
                }) ||
                (vendor.name.slice(0, 9) === "Clubhouse" && {
                  height: "100%",
                  width: "auto",
                  paddingTop: 0,
                }) ||
                (vendor.name.slice(0, 9) === "Kiosk" && {
                  height: "80%",
                  width: "auto",
                  paddingTop: 0,
                }) ||
                (vendor.name.slice(0, 9) !== "Clubhouse" &&
                  vendor.name !== "Kiosk" && {
                    width: "100%",
                    height: "auto",
                    paddingTop: 0,
                  }) ||
                (vendor.name.slice(0, 9) !== "Clubhouse" &&
                  vendor.name === "Kiosk" && {
                    width: "100%",
                    height: "auto",
                    paddingTop: 0,
                  })
              }
              alt=""
              src={
                (vendor.name.slice(0, 9) === "Clubhouse" ? Logo : "") ||
                (vendor.name === "OK Databingo" ? LogoOK : "") ||
                (vendor.name === "Win Games" ? LogoWin : "") ||
                (vendor.name === "Norsk Tipping" ? LogoNT : "") ||
                (vendor.name === "Kiosk" ? LogoKiosk : "") ||
                (vendor.name === "EBS" ? LogoEBS : "")
              }
            />
          </Avatar>
        </Grid>

        {/*HEADER ITEM TEXT NAME*/}

        <Grid
          item
          xs={7}
          sx={{
            height: "75px",
            width: "100%",
            marginTop: "10px",
            marginLeft: 0,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              paddingTop: 2,
              textAlign: "center",
            }}
          >
            {vendor.name}
          </Typography>
        </Grid>

        {/*HEADER ITEM DIVIDER*/}

        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
          }}
        >
          <Divider
            sx={{
              color: "black",
              borderColor: "black",
              borderWidth: "1.5px",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VendorItemHeader;
