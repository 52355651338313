import React, { useEffect, useState, useContext, useRef } from "react";
import { TextField } from "@mui/material";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";
import { NorwegianId } from "norwegian-national-id-validator";

function ModalHybridUserEdit() {
  const { t } = useTranslation();

  const {
    enviroment,
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsFullUser,
    updateIsHybridUserEditing,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    updateShowModalEditUserLimits,
    parentInfo,
    updateCurrentUserID,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updateTotal,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    logoutUser,
    resetTicketsCH,
    updateIsFullUserEditing,
  } = useContext(AppContext);

  const [isUserDeletedConfirm, setIsUserDeletedConfirm] = useState(false);
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  const [showSMS, setShowSMS] = useState(false);

  const [smsHybridRegistration, setSmsHybridRegistration] = useState("");
  const smsHybridRegistrationRef = useRef(smsHybridRegistration);
  useEffect(() => {
    console.log("smsHybridRegistration", smsHybridRegistration);
    smsHybridRegistrationRef.current = smsHybridRegistration;
  }, [smsHybridRegistration]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const [isUserConfirmed, setIsUserConfirmed] = useState("");

  useEffect(() => {
    if (parentInfo) {
      return;
    }
    if (userInfo.firstname !== "") {
      setUsername(userInfo.firstname);
    }
    if (userInfo.mobile !== "") {
      setPhonenumber(userInfo.mobile);
    }

    if (userInfo.lastname !== "") {
      setLastname(userInfo.lastname);
    }
    if (userInfo["SSN ID"] !== "") {
      setSSNID(userInfo["SSN ID"]);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!parentInfo) {
      return;
    }
    if (parentInfo.firstname !== "") {
      setUsername(parentInfo.firstname);
    }
    if (parentInfo.mobile !== "") {
      setPhonenumber(parentInfo.mobile);
    }

    if (parentInfo.lastname !== "") {
      setLastname(parentInfo.lastname);
    }
    if (parentInfo["SSN ID"] !== "") {
      setSSNID(parentInfo["SSN ID"]);
    }
  }, [parentInfo]);

  // #region HANDLERS
  //* INPUT HANDLERS
  const [ssnid, setSSNID] = useState("");
  const handleSSNIDChange = (event) => {
    setSSNID(event.target.value);
  };
  const ssnidRef = useRef(ssnid);
  useEffect(() => {
    ssnidRef.current = ssnid;
  }, [ssnid]);

  const [username, setUsername] = useState("");
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const usernameRef = useRef(username);
  useEffect(() => {
    usernameRef.current = username;
  }, [username]);

  const [lastname, setLastname] = useState("");
  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };
  const lastnameRef = useRef(lastname);
  useEffect(() => {
    lastnameRef.current = lastname;
  }, [lastname]);

  const [phonenumber, setPhonenumber] = useState("");
  const handleChangePhonenumber = (event) => {
    let input = event.target.value.replace(/\D/g, "");

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + " " + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + " " + input.substring(6, 9);
    }

    setPhonenumber(input);
  };
  const phonenumberRef = useRef(phonenumber);
  useEffect(() => {
    phonenumberRef.current = phonenumber;
  }, [phonenumber]);

  async function isEightDigitNumber(value) {
    const digits = value.replace(/\s/g, "_");

    if (digits[0] !== "4" && digits[0] !== "9") {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  // #region SOCKETS
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleSearchMemberPreFullRegister = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      if (
        data.phonenumber &&
        phonenumberRef.current !== userInfo.mobile &&
        phonenumberRef.current !== parentInfo.mobile
      ) {
        toast.error(t("error.errorPhonenumberExists"));
        return;
      }
      if (
        data.ssnid &&
        ssnidRef.current !== userInfo["SSN ID"] &&
        ssnidRef.current !== parentInfo["SSN ID"]
      ) {
        toast.error(t("error.errorSSNIDExists"));
        return;
      }

      if (
        (parentInfoRef.current &&
          parentInfoRef.current.mobile !== phonenumberRef.current) ||
        (userInfoRef.current &&
          userInfoRef.current.mobile !== phonenumberRef.current)
      ) {
        var dataToSend = {
          phonenumber: phonenumberRef.current,
          sessionID: session,
          action: "sms-hybrid",
        };
        socket.emit("smsHybridRegistration", dataToSend);

        setShowSMS(true);
        socket.emit("activateSMS", {
          bingoName: hallRef.current,
        });
      } else {
        setIsUserConfirmed(true);
      }
    };

    const handleNewHybridMember = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        setShowSMS(false);
        setIsUserConfirmed(false);
        return;
      }
      var data = {
        auth_value: data.username,
        searchByUsername: true,
        isExact: true,
        action: "searchMember",
        session: session,
      };
      socket.emit("searchMember", data);
    };

    const handleSmsHybridRegistration = (data) => {
      setSmsHybridRegistration(data);

      if (!data) {
        toast.error(t("error.error"));
        return;
      }

      setSmsHybridRegistration(data);
    };

    const handleSearchMember = (data) => {
      if (
        !data ||
        !data.records ||
        data.records.length === 0 ||
        data.records.length > 1
      ) {
        toast.error(t("error.error"));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]["7 digit PIN"]);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === "anon" ||
        (data.records[0].firstname === "" && data.records[0].mobile === "")
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            searchByID: true,
            action: "searchMemberParent",
            session: session,
          };
          socket.emit("searchMemberParent", data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === "hybrid") {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: "registerLastUsers",
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: dataToRegisterObj[0]["7 digit PIN"],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ""
            ? "Uregistrert"
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);

      updateFocusOnMainScreen(true);
      updateIsHybridUserEditing(false);
    };

    const handleSendPincodeTerminal = (data) => {
      if (smsHybridRegistrationRef.current === "") {
        return;
      }

      if (parseInt(data.pincode) !== smsHybridRegistrationRef.current) {
        toast.error(t("error.errorUserFailCode"));
        return;
      }
      setIsUserConfirmed(true);
      socket.emit("deactivateSMS", {
        bingoName: hallRef.current,
      });
    };

    const handleDeleteMember = (data) => {
      console.log("delete member", data);
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      setIsUserDeletedConfirm(false);
      setIsUserDeleted(true);
    };

    socket.on("searchMemberPreFullRegister", handleSearchMemberPreFullRegister);
    socket.on("smsHybridRegistration", handleSmsHybridRegistration);
    socket.on("searchMember", handleSearchMember);
    socket.on("newHybridMember", handleNewHybridMember);
    socket.on("sendPincodeTerminal", handleSendPincodeTerminal);
    socket.on("deleteHybridMember", handleDeleteMember);

    return () => {
      socket.off(
        "searchMemberPreFullRegister",
        handleSearchMemberPreFullRegister
      );
      socket.off("smsHybridRegistration", handleSmsHybridRegistration);
      socket.off("searchMember", handleSearchMember);
      socket.off("newHybridMember", handleNewHybridMember);
      socket.off("sendPincodeTerminal", handleSendPincodeTerminal);
      socket.off("deleteHybridMember", handleDeleteMember);
    };
  }, [socket]);

  // #region LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmRegister = async () => {
    const validation = NorwegianId(ssnidRef.current);
    if (
      !validation.isValid() &&
      enviroment === "prod" &&
      ssnidRef.current !== ""
    ) {
      toast.error(t("error.errorSSNIDInvalid"));
      return;
    }
    if (
      validation.age() < 18 &&
      enviroment === "prod" &&
      ssnidRef.current !== ""
    ) {
      toast.error(t("error.errorSSNIDUnderAge"));
      return;
    }
    const isPhonenumber = await isEightDigitNumber(phonenumber);

    if (!isPhonenumber) {
      toast.error(t("error.errorPhonenumberInput"));
      return;
    }
    if (username.length < 2) {
      toast.error(t("error.errorUsernameInput"));
      return;
    }

    if (lastname.length < 2) {
      toast.error(t("error.errorLastnameEmpty"));
      return;
    }

    /*
    if (phonenumber === userInfo.mobile) {
      registerAndEmitUser();
      return;
    }
    */

    var dataToSend = {
      phonenumber: phonenumberRef.current,
      ssnid: ssnidRef.current,
      action: "searchMemberPreFullRegister",
      session: sessionRef.current,
    };
    socket.emit("searchMemberPreFullRegister", dataToSend);
  };

  //* CONFIRM SMS REGISTRATION (and register the user)
  const confirmSMS = () => {
    registerAndEmitUser();
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    socket.emit("deactivateSMS", {
      bingoName: hallRef.current,
    });
    updateFocusOnMainScreen(true);
    updateIsHybridUserEditing(false);
  };
  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    if (data.operator === "") {
      return;
    }
    socket.emit("registerLastUsers", data);
  };

  //* Emit the user for the register
  const registerAndEmitUser = () => {
    var data = {
      action: "newHybridMember",
      username: usernameRef.current,
      lastname: lastnameRef.current,
      phonenumber: phonenumberRef.current,
      session: session,
      id: userInfo.id,
      type: "editHybrid",
      userInfo: userInfo,
      ssnid: ssnidRef.current,
    };

    socket.emit("newHybridMember", data);
  };

  const handleUserLimits = () => {
    updateIsHybridUserEditing(false);
    updateShowModalEditUserLimits(true);
  };

  const requestDeletedUser = () => {
    setIsUserDeletedConfirm(true);
  };

  const cancelDeletedUser = () => {
    setIsUserDeletedConfirm(false);
  };

  const handleDelete = () => {
    console.log("delete user");

    var data = {
      action: "deleteHybridMember",
      id: parentInfo ? parentInfo.id : userInfo.id,
      ssnid: ssnidRef.current,
      username: usernameRef.current,
      firstname: usernameRef.current,
      lastname: lastnameRef.current,
      phonenumber: phonenumberRef.current,
      session: sessionRef.current,
      operator: operator,
      userInfo: parentInfo ? parentInfo : userInfo,
      type: "editHybrid",
    };

    socket.emit("deleteHybridMember", data);
  };

  const handleUserDeleted = () => {
    updateShowUserInfo(false);
    updatePrint(false);
    resetTransactions();
    updateReceiptToPrint(false);
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateCurrentUser("");
    updateCurrentUserID("");
    updateUserLogin(false);
    updatePaymentType("");
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser("");
    updateCurrentUserID("");
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();

    updateFocusOnMainScreen(true);
    updateIsHybridUserEditing(false);
  };

  return (
    <div id="react-confirm-alert">
      <div class="react-confirm-alert-overlay">
        <div class="react-confirm-alert">
          {isUserConfirmed ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: "1000px" }}
            >
              <h1>{t("sales.editHybridUser")}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    confirmSMS();
                  }}
                >
                  {t("sales.confirm")}
                </button>
              </div>
            </div>
          ) : isUserDeletedConfirm ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: "1000px" }}
            >
              <h1>{t("sales.deleteUser")}</h1>
              <p>{t("sales.deleteUserTitle")}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  {t("sales.confirm")}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancelDeletedUser();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </div>
          ) : isUserDeleted ? (
            // Render for user deleted
            <div
              className="react-confirm-alert-body"
              style={{ width: "1000px" }}
            >
              <h1>{t("sales.deleteUserConfirm")}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleUserDeleted();
                  }}
                >
                  {t("sales.confirm")}
                </button>
              </div>
            </div>
          ) : !showSMS ? (
            <div class="react-confirm-alert-body" style={{ width: "1000px" }}>
              <h1>{t("sales.registerNewHybridUser")}</h1>
              <TextField
                autoComplete="off"
                label={"Fødselsnummer"}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  handleSSNIDChange(e);
                }}
                placeholder={ssnid}
                className="modal-textfield"
                value={ssnid}
              />

              <TextField
                autoComplete="off"
                label={t("sales.phonenumber") + "*"}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  handleChangePhonenumber(e);
                }}
                placeholder={phonenumber}
                className="modal-textfield"
                value={phonenumber}
              />

              <TextField
                autoComplete="off"
                label={t("sales.name") + "*"}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  handleUsernameChange(e);
                }}
                placeholder={username}
                className="modal-textfield"
                value={username}
              />

              <TextField
                autoComplete="off"
                label={t("sales.lastname") + "*"}
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  handleLastnameChange(e);
                }}
                placeholder={lastname}
                className="modal-textfield"
                value={lastname}
              />

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    confirmRegister();
                  }}
                >
                  {t("sales.editUserConfirm")}
                </button>
                {enviroment !== "prod" && (
                  <button
                    className="btn-custom-thirdOption"
                    onClick={() => {
                      handleUserLimits();
                    }}
                  >
                    {t("header.userLimits")}
                  </button>
                )}
                <button
                  className="btn-custom-delete"
                  onClick={() => {
                    requestDeletedUser();
                  }}
                >
                  {t("sales.deleteUser")}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancel();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </div>
          ) : (
            <div class="react-confirm-alert-body" style={{ width: "  1000px" }}>
              <h1>{t("sales.smsTitle")}</h1>
              <p>{t("sales.smsText1")}</p>
              <p>{t("sales.smsText2")}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancel();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalHybridUserEdit;
