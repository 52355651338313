import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Grid, Checkbox } from "@mui/material";

import Calculator from "../../../components/Calculator/Calculator";
import SellScreenButtons from "./SellScreenButtons";
import SellScreenDescription from "./SellScreenDescription";
import Multipliers from "../../../components/Multipliers/Multipliers";

import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";

function SellScreen() {
  const { t } = useTranslation();
  const {
    isOKBingoEnabled,
    vendorInfo,
    sellType,
    userInfo,
    isFullUser,
    userChildren,
    parentInfo,
    currentUser,
    updateRefreshUser,
    updateShowUserInfo,
    updateShowUserInfoSibling,
    updateShowUserInfoOK,
    updateShowUserInfoSiblingOK,
    currentTicketEBS,
  } = useContext(AppContext);

  const [amount, setAmount] = useState("0");
  const [executeConfirm, setExecuteConfirm] = useState(false); //Enter button, send to the component

  useEffect(() => {
    console.log("IsokBingoEnabled", isOKBingoEnabled);
  }, [isOKBingoEnabled]);

  //* Check if the current user is a child, to block refill if is not
  const [isCurrentUserChildren, setIsCurrentUserChildren] = useState(false);
  const isCurrentUserChildrenRef = useRef(isCurrentUserChildren);
  useEffect(() => {
    isCurrentUserChildrenRef.current = isCurrentUserChildren;
  }, [isCurrentUserChildren]);

  useEffect(() => {
    if (parentInfo) {
      setIsCurrentUserChildren(true);
      return;
    }
    if (userChildren.length === 0) {
      setIsCurrentUserChildren(false);
      return;
    }

    const isCurrentUserNotInUserChildren = !userChildren.some(
      (child) => child.ticket7Pincode === currentUser
    );

    setIsCurrentUserChildren(!isCurrentUserNotInUserChildren);
  }, [currentUser, userChildren]);

  useEffect(() => {
    updateRefreshUser(true);
    updateShowUserInfo(false);
    updateShowUserInfoSibling(false);
    updateShowUserInfoOK(false);
    updateShowUserInfoSiblingOK(false);
  }, []);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);
  const isFullUserRef = useRef(isFullUser);
  useEffect(() => {
    isFullUserRef.current = isFullUser;
  }, [isFullUser]);

  //* Update amount related
  useEffect(() => {
    console.log(vendorInfo.name, sellType, amount);
    if (
      isRefill &&
      !isCurrentUserChildrenRef.current &&
      vendorInfo.name.slice(0, 9) === "Clubhouse"
    ) {
      setAmount("0");
      return;
    }

    if (
      isPayoutTicket &&
      sellType === 5 &&
      isFullUserRef.current &&
      !parentInfoRef.current &&
      vendorInfo.name !== "OK Databingo" &&
      isOKBingoEnabled
    ) {
      setAmount("0");
      return;
    }
    if (
      isPayoutTicket &&
      sellType === 5 &&
      amount > parseInt(userInfo.balances.wallet) &&
      vendorInfo.name !== "OK Databingo" &&
      isOKBingoEnabled
    ) {
      setAmount(userInfo.balances.wallet);
      return;
    }
    if (parseInt(amount) > 1000 && (sellType === 14 || sellType === 13)) {
      return;
    }

    if (
      parseInt(amount) > 1000 &&
      ((!isPayoutTicket && sellType !== 2 && sellType !== 5) ||
        (isPayoutTicket && sellType === 6))
    ) {
      setAmount("1000");
      return;
    }

    /*
    if (userInfo.balances) {
      if (parseInt(userInfo.balances.wallet) > 1000 && isPayoutTicket) {
        setAmount(userInfo.balances.wallet);
      }
    }
    */
  }, [amount]);

  const updateAmount = useCallback(
    (value) => {
      if (
        vendorInfo.name === "OK Databingo" &&
        sellType === 5 &&
        isOKBingoEnabled
      ) {
        console.log("return", isPayoutTicket, isOKBingoEnabled, isRefill);
        return;
      }

      if (
        isRefill &&
        !isCurrentUserChildrenRef.current &&
        vendorInfo.name.slice(0, 9) === "Clubhouse"
      ) {
        setAmount("0");
        return;
      }
      if (
        isPayoutTicket &&
        sellType === 5 &&
        isFullUserRef.current &&
        !parentInfoRef.current &&
        vendorInfo.name !== "OK Databingo" &&
        isOKBingoEnabled
      ) {
        setAmount("0");
        return;
      }

      //refill ebs
      /*
      if (
        currentTicketEBS &&
        sellType === 25 &&
        parseInt(amount + value) > parseInt(currentTicketEBS.ebsInfo.amount)
      ) {
        setAmount(currentTicketEBS.ebsInfo.amount);
        return;
      }
      */
      if (currentTicketEBS && sellType === 25) {
        return;
      }
      /*
    if (isFullUser && vendorInfo.name.slice(0, 9) === "Clubhouse") {
      return;
    }
    */

      if (
        (amount === 0 || amount === "0") &&
        (value === "0" || value === "00")
      ) {
        return;
      }

      if ((amount === "0" || amount === 0) && value !== "backspace") {
        setAmount(value);
        return;
      }
      if ((amount === "0" || amount === 0) && value === "backspace") {
        return;
      }
      if (
        value === "backspace" &&
        amount.toString().length === 1 &&
        (amount !== "0" || amount !== 0)
      ) {
        setAmount("0");
        return;
      }
      if (
        value === "backspace" &&
        amount.toString().length > 1 &&
        (amount !== "0" || amount !== 0)
      ) {
        setAmount(amount.toString().substring(0, amount.toString().length - 1));
        return;
      }

      setAmount(amount + value);
    },
    [amount]
  );

  const updateAmountShortcut = (value) => {
    if (
      isRefill &&
      !isCurrentUserChildrenRef.current &&
      vendorInfo.name.slice(0, 9) === "Clubhouse"
    ) {
      setAmount("0");
      return;
    }
    if (
      isPayoutTicket &&
      sellType === 5 &&
      isFullUserRef.current &&
      !parentInfoRef.current &&
      vendorInfo.name !== "OK Databingo"
    ) {
      console.log("ispayoutvalue", value);

      setAmount("0");
      return;
    }

    setAmount(value.toString());
  };

  const updateAmountShortcutOKBingo = (value) => {
    setAmount(value.toString());
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      // Check if the key pressed is a number
      if (event.key >= "0" && event.key <= "9") {
        updateAmount(event.key);
      } else if (event.key === "Backspace") {
        updateAmount("backspace");
      } else if (event.key === "Enter") {
        if (amount !== "0" && amount > 0) {
          //setExecuteConfirm(true);
        }
      }
    };

    // Add event listener for keydown
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [updateAmount]);

  const [description, setDescription] = useState("");
  const [sortDescription, setSortDescription] = useState("");
  const [action, setAction] = useState("");
  const [isRefill, setIsRefill] = useState(false);

  const [sum, setSum] = useState(true);

  const [isShortcut, setIsShorcut] = useState(false); //Shortcuts enable, type 1,2,3,6
  const [isTicketCH, setIsTicketCH] = useState(false); //Multipliers enable
  const [isPayoutTicket, setIsPayoutTicket] = useState(false); //Multipliers enable
  const [isUserSaldo, setIsUserSaldo] = useState(false); //Multipliers enable

  useEffect(() => {
    if (vendorInfo.name.slice(0, 9) === "Clubhouse") {
      setDescription("Clubhouse");
      setSortDescription("CH");
    } else if (vendorInfo.name === "OK Databingo") {
      setDescription("OK Databingo");
      setSortDescription("OK D.");
    } else if (vendorInfo.name === "Win Games") {
      setDescription("Win Games");
      setSortDescription("WG");
    } else if (vendorInfo.name === "Norsk Tipping") {
      setDescription("Norsk Tipping");
      setSortDescription("NT");
    } else if (vendorInfo.name === "EBS") {
      setDescription("EBS");
      setSortDescription("EBS");
    }

    switch (sellType) {
      case 1:
        setAction("Sett inn");
        setSum(true);
        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;
      case 2:
        setAction("Ta ut");
        setSum(false);
        setIsShorcut(false);
        setIsPayoutTicket(false);
        setIsUserSaldo(true);
        break;
      case 3:
        setAction("Ny Billett");
        setSum(true);
        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;
      case 4:
        setAction("Ny Billett");
        setSum(true);
        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;
      case 5:
        setAction("Utbetaling");
        setSum(false);
        setIsShorcut(false);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;
      case 6:
        setAction("Fylle på");
        setSum(true);

        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);

        break;
      case 13:
        setAction("Inn");
        setSum(true);
        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;

      case 14:
        setAction("Ut");
        setSum(false);
        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;
      case 24:
        setAction("Ny Billett");
        setSum(true);
        setIsShorcut(true);
        setIsPayoutTicket(false);
        setIsUserSaldo(false);
        break;
      case 25:
        setAction("Utbetaling");
        setSum(false);
        setIsShorcut(false);
        setIsPayoutTicket(true);
        setIsUserSaldo(false);
        break;
      case 26:
        setAction("Fylle på");
        setSum(true);
        setIsShorcut(true);
        setIsPayoutTicket(true);
        setIsUserSaldo(false);
        break;
      default:
        break;
    }

    if (sellType === 6 || sellType === 26) {
      setIsRefill(true);
    } else {
      setIsRefill(false);
    }

    if (vendorInfo.name === "OK Databingo" && !isOKBingoEnabled) {
      setIsShorcut(false);
      setIsRefill(false);
    }

    if (vendorInfo.name.slice(0, 9) === "Clubhouse" && sellType === 3) {
      setIsTicketCH(true);
    }
    if (
      vendorInfo.name.slice(0, 9) === "Clubhouse" &&
      (sellType === 5 || sellType === 6)
    ) {
      setIsPayoutTicket(true);
      //Search for amount to fulfilled the pay out
      if (
        (isFullUser && vendorInfo.name.slice(0, 9) === "Clubhouse") ||
        sellType === 6
      ) {
        return;
      }
      setAmount(userInfo.balances ? userInfo.balances.wallet : 0);
    }
    if (
      vendorInfo.name === "OK Databingo" &&
      (sellType === 5 || sellType === 6)
    ) {
      setIsPayoutTicket(true);
    }
  }, []);

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "10px",
      }}
    >
      {/*Header*/}
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {/*Description*/}
        <Grid
          item
          xs={6}
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <SellScreenDescription
            amount={amount}
            description={description}
            action={action}
            isShortcut={isShortcut}
            isPayoutTicket={isPayoutTicket}
            updateAmountShortcut={updateAmountShortcut}
            isUserSaldo={isUserSaldo}
            isRefill={isRefill}
          />
        </Grid>

        {/*Multipliers, Calculator, Buttons*/}
        <Grid
          item
          xs={6}
          sx={{
            padding: "10px",
            height: "100%",
          }}
        >
          <Multipliers isTicketCH={isTicketCH} />
          <div style={{ height: "729px" }}>
            {(sellType === 6 || sellType === 1) && (
              <div
                style={{
                  marginTop: "-45px",
                  display: "flex",
                  alignContent: "space-around",
                  width: "100%",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  lineHeight: "0.3",
                  marginBottom: "17px",
                  alignItems: "center",
                  height: "30px",
                }}
              >
                <p>{t("sales.printAction")}</p>
                <Checkbox
                  checked={true}
                  onChange={() => {
                    //updatePrint(!print);
                  }}
                  sx={{
                    padding: "9px 0 9px 9px",
                    marginRight: "-8px",
                    "& .MuiIconButton-label": {
                      // You can try to target inner elements
                    },
                  }}
                />
              </div>
            )}
            <Calculator updateAmount={updateAmount} isSellScreen={true} />
            <SellScreenButtons
              amount={amount}
              sum={sum}
              sortDescription={sortDescription}
              action={action}
              executeConfirm={executeConfirm}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellScreen;
