import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid, Checkbox } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AppContext } from "../../../context/Context";
import { SocketContext } from "../../../context/SocketContext";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

function TransactionList() {
  const { t } = useTranslation();
  const {
    isPaymentOpen,
    ticketsOK,
    isSellerOpen,
    OKBingoID,
    isOKBingoEnabled,
    addCancelTransaction,
    ticketsCH,
    withdraw,
    session,
    transactions,
    deleteTransactions,
    operator,
    hall,
    currentUser,
    userInfo,
    total,
    payCard,
    payCardTemp,
    payCash,
    payCashTemp,
    change,
    paySpillerkonto,
    paySpillerkontoTemp,
    print,
    updatePrint,
    parentInfo,
    updateRefreshUser,
    printer,
    updateIsTerminalBusy,
    updateIsLoadingScreen,
    updateMaxAllowed,
    updateShowIsLimitSurpassedTransaction,
    updateIsDailyLimitSurpassed,
    updateCurrentTicketOK,
    updateShowUserInfoOK,
    updateTransactions,
    updateIsLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateShowIsLimitSurpassed,
  } = useContext(AppContext);
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    //console.log("transactions", transactions);
  }, [transactions]);

  useEffect(() => {
    //console.log("ticketsOK", ticketsOK);
  }, [ticketsOK]);

  const [amount, setAmount] = useState(0);
  const amountRef = useRef(amount);
  useEffect(() => {
    amountRef.current = amount;
  }, [amount]);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  const [okTicketID, setOkTicketID] = useState(false);
  const okTicketIDRef = useRef(okTicketID);
  useEffect(() => {
    //console.log("NEW OK TICKET", okTicketID);
    okTicketIDRef.current = okTicketID;
  }, [okTicketID]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);

  const [transactionToDelete, setTransactionToDelete] = useState({});
  const transactionToDeleteRef = useRef(transactionToDelete);
  useEffect(() => {
    transactionToDeleteRef.current = transactionToDelete;
  }, [transactionToDelete]);

  const transactionsRef = useRef(transactions);
  useEffect(() => {
    transactionsRef.current = transactions;
  }, [transactions]);

  useEffect(() => {
    const handleSettInnCancel = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleTaUtCancel = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleNyBillettCHCancel = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleFyllePaCHCancel = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleUtbetalingCancel = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleCancelCashinEBS = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleCancelCashoutEBS = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleCancelRefillEBS = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleCancelCashinOK = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      if (isOKBingoEnabled) {
        await redifineTransactions();

        setTimeout(() => {
          setTransactionToDelete({});
        }, 200);
        setTimeout(() => {
          updateRefreshUser(true);
        }, 1600);
        return;
      }

      setOkTicketID(data.ticketID);
    };

    const handleBroadcastCancelCashinOK = (data) => {
      console.log("handleBroadcastCashinOK", data);

      if (data.errorNumber !== "") {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit("registerTransactionCashinOK", {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: parentInfoRef.current
            ? parentInfoRef.current
            : userInfoRef.current,
          amount: parseInt(amountRef.current),
          provider: "OK D",
          description: "Avbryt Ny Billett",
          okID: data.ticketNumber,
          action: "registerTransactionCashinOK",
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionCancelCashinOK = (data) => {
      console.log("handleRegisterTransactionCashinOK", data);
      if (!data) {
        toast.error(t("error.error"));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      updateTransactions({
        name: "Avbryt Ny Billett - OK D",
        amount: parseInt(amountRef.current) * 1,
        type: "transaction",
        ticketData: data,
        ticketID: okTicketIDRef.current,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    const handleCancelCashoutOK = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleCancelRefillOK = async (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      await redifineTransactions();

      setTimeout(() => {
        setTransactionToDelete({});
      }, 200);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
    };

    const handleCashoutOK = (data) => {
      console.log("handleCashoutOK", data);
      if (!data) {
        toast.error(t("error.error"));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }
      setOkTicketID(data.ticketID);
    };

    const handleBroadcastCashoutOK = (data) => {
      console.log("handleBroadcastCashoutOK", data);

      if (data.errorNumber !== "") {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit("registerTransactionCashoutOK", {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: parentInfoRef.current
            ? parentInfoRef.current
            : userInfoRef.current,
          amount: parseInt(data.amount),
          provider: "OK D.",
          description: "Avbryt Ny Billett",
          okID: data.ticketNumber,
          action: "registerTransactionCashoutOK",
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionCashoutOK = (data) => {
      console.log("handleRegisterTransactionCashinOK", data);
      if (!data) {
        toast.error(t("error.error"));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      updateTransactions({
        name: "Utbetaling" + " - " + "OK D.",
        amount: parseInt(amountRef.current) * -1,
        type: "transaction",
        ticketData: data,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateShowUserInfoOK(false);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
      }, 300);
    };

    socket.on(
      "registerTransactionCancelCashin",
      handleRegisterTransactionCancelCashinOK
    );
    socket.on("broadcastCashoutOK", handleBroadcastCancelCashinOK);

    socket.on("settInnCancel", handleSettInnCancel);
    socket.on("taUtCancel", handleTaUtCancel);
    socket.on("nyBillettCHCancel", handleNyBillettCHCancel);
    socket.on("fyllePaCHCancel", handleFyllePaCHCancel);
    socket.on("utbetalingCancel", handleUtbetalingCancel);

    socket.on("cancelCashinEBS", handleCancelCashinEBS);
    socket.on("cancelCashoutEBS", handleCancelCashoutEBS);
    socket.on("cancelRefillEBS", handleCancelRefillEBS);

    socket.on("cancelCashinOK", handleCancelCashinOK);
    socket.on("cancelCashoutOK", handleCancelCashoutOK);
    socket.on("cancelRefillOK", handleCancelRefillOK);

    return () => {
      socket.off("cashoutOK", handleCashoutOK);
      socket.off("broadcastCashoutOK", handleBroadcastCashoutOK);
      socket.off(
        "registerTransactionCashoutOK",
        handleRegisterTransactionCashoutOK
      );

      socket.off("settInnCancel", handleSettInnCancel);
      socket.off("taUtCancel", handleTaUtCancel);
      socket.off("nyBillettCHCancel", handleNyBillettCHCancel);
      socket.off("fyllePaCHCancel", handleFyllePaCHCancel);
      socket.off("utbetalingCancel", handleUtbetalingCancel);

      socket.off("cancelCashinEBS", handleCancelCashinEBS);
      socket.off("cancelCashoutEBS", handleCancelCashoutEBS);
      socket.off("cancelRefillEBS", handleCancelRefillEBS);

      socket.off("cancelCashinOK", handleCancelCashinOK);
      socket.off("cancelCashoutOK", handleCancelCashoutOK);
      socket.off("cancelRefillOK", handleCancelRefillOK);
    };
  }, [socket]);

  const handleDelete = (transactionToDeleteItem) => {
    if (isSellerOpen || isPaymentOpen) {
      toast.error(t("error.errorCancelOpenSell"));
      return;
    }
    setTransactionToDelete(transactionToDeleteItem);
    if (transactionToDeleteItem.name === "Sett inn - CH") {
      var data = {
        operator: operator,
        bingoName: hall,
        session: sessionRef.current,
        sessionID: sessionRef.current,
        provider: "CH",
        description: "Avbryt Sett inn",
        amount: parseInt(transactionToDeleteItem.amount) * -1,
        cashoutType: "cash_from_wallet",
        useSMS: false,
        action: "settInnCancel",

        ticketID: parentInfo ? parentInfo.id : userInfo.id,
        ticketUsername: parentInfo ? parentInfo.username : userInfo.username,
        ticket7Pincode: parentInfo ? parentInfo["7 digit PIN"] : currentUser,
        playerID: parentInfo ? parentInfo.id : userInfo.id,

        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
      };

      socket.emit("settInnCancel", data);
      /*
      redifineTransactions(transactionToDelete);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
      */

      return;
    }

    if (transactionToDeleteItem.name === "Ta ut - CH") {
      //CLAIM CASHIN KONTO
      var data = {
        operator: operator,
        bingoName: hall,
        session: sessionRef.current,
        sessionID: sessionRef.current,
        provider: "CH",
        description: "Avbryt Ta ut",
        amount: parseInt(transactionToDeleteItem.amount) * -1,
        usePrinter: false,
        useSMS: false,
        printBarcode: false,
        qrMessage: false,
        isChild: false,
        action: "taUtCancel",

        ticketID: parentInfo ? parentInfo.id : userInfo.id,
        ticketUsername: parentInfo ? parentInfo.username : userInfo.username,
        ticket7Pincode: parentInfo ? parentInfo["7 digit PIN"] : currentUser,
        playerID: parentInfo ? parentInfo.id : userInfo.id,

        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
        dataToSaveTransaction: {
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: "CH",
          description: "Avbryt Ta ut",
          amount: parseInt(transactionToDeleteItem.amount) * -1,
          user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
          userInfo: !parentInfo ? userInfo : parentInfo,
          action: "newTransaction",
          ticketID: userInfo.id,
          ticketUsername: userInfo.username,
          ticket7Pincode: currentUser,
        },
      };

      socket.emit("taUtCancel", data);
      /*
      redifineTransactions(transactionToDelete);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
      */

      return;
    }

    if (transactionToDeleteItem.name === "Utbetaling fra terminal - CH") {
      //CLAIM CASHIN KONTO
      var data = {
        operator: operator,
        bingoName: hall,
        session: sessionRef.current,
        sessionID: sessionRef.current,
        provider: "CH",
        description: "Avbryt Utbetaling fra terminal",
        amount: parseInt(transactionToDeleteItem.amount) * -1,
        usePrinter: false,
        useSMS: false,
        printBarcode: false,
        qrMessage: false,
        isChild: false,
        action: "taUtCancel",

        ticketID: parentInfo ? parentInfo.id : userInfo.id,
        ticketUsername: parentInfo ? parentInfo.username : userInfo.username,
        ticket7Pincode: parentInfo ? parentInfo["7 digit PIN"] : currentUser,
        playerID: parentInfo ? parentInfo.id : userInfo.id,

        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
        dataToSaveTransaction: {
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: "CH",
          description: "Avbryt Utbetaling fra terminal",
          amount: parseInt(transactionToDeleteItem.amount) * -1,
          user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
          userInfo: !parentInfo ? userInfo : parentInfo,
          action: "newTransaction",
          ticketID: userInfo.id,
          ticketUsername: userInfo.username,
          ticket7Pincode: currentUser,
        },
      };

      socket.emit("taUtCancel", data);
      /*
      redifineTransactions(transactionToDelete);
      setTimeout(() => {
        updateRefreshUser(true);
      }, 1600);
      */

      return;
    }

    if (transactionToDeleteItem.name === "Ny Billett - CH") {
      //MODAL RETURN CASH

      if (
        !transactionToDeleteItem.ticketData.player_id ||
        !transactionToDeleteItem.ticketData.username ||
        !transactionToDeleteItem.ticketData.pin
      ) {
        toast.error(t("error.error"));
        return;
      }

      var data = {
        operator: operator,
        bingoName: hall,
        session: sessionRef.current,
        sessionID: sessionRef.current,
        provider: "CH",
        description: "Avbryt Ny Billett",
        amount: parseInt(transactionToDeleteItem.amount) * -1,
        ticketID: transactionToDeleteItem.ticketData.player_id,
        ticketUsername: transactionToDeleteItem.ticketData.username,
        ticket7Pincode: transactionToDeleteItem.ticketData.pin,
        cashoutType: "cash_from_wallet",
        playerID: transactionToDeleteItem.ticketData.player_id,
        useSMS: false,
        action: "nyBillettCHCancel",
        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
      };

      socket.emit("nyBillettCHCancel", data);

      //redifineTransactions(transactionToDelete);
      //removeTicketsCH();

      return;
    }

    if (transactionToDeleteItem.name === "Utbetaling - CH") {
      //MODAL RETURN CARD PAYMENT

      if (
        !transactionToDeleteItem.ticketData.player_id ||
        !transactionToDeleteItem.ticketData.username ||
        !transactionToDeleteItem.ticketData.pin
      ) {
        toast.error(t("error.error"));
        return;
      }

      var data = {
        operator: operator,
        bingoName: hall,
        session: sessionRef.current,
        sessionID: sessionRef.current,
        provider: "CH",
        description: "Avbryt Ta ut",
        amount: parseInt(transactionToDeleteItem.amount) * -1,
        usePrinter: false,
        useSMS: false,
        printBarcode: false,
        qrMessage: false,
        isChild: false,
        action: "utbetalingCancel",

        ticketID: transactionToDeleteItem.ticketData.player_id,
        ticketUsername: transactionToDeleteItem.ticketData.username,
        ticket7Pincode: transactionToDeleteItem.ticketData.pin,
        playerID: transactionToDeleteItem.ticketData.player_id,

        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
        dataToSaveTransaction: {
          operator: operator,
          bingoName: hall,
          sessionID: session,
          provider: "CH",
          description: "Avbryt Utbetaling",
          amount: parseInt(transactionToDeleteItem.amount) * -1,
          user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
          userInfo: !parentInfo ? userInfo : parentInfo,
          action: "newTransaction",
          ticketID: ticketsCH.length > 0 ? ticketsCH[0].userID : userInfo.id,
          ticketUsername:
            ticketsCH.length > 0 ? ticketsCH[0].username : userInfo.username,
          ticket7Pincode:
            ticketsCH.length > 0 ? ticketsCH[0].user7Pincode : currentUser,
        },
      };
      socket.emit("utbetalingCancel", data);
      //redifineTransactions(transactionToDelete);
      //removeTicketsCH();

      return;
    }

    if (transactionToDeleteItem.name === "Fylle på - CH") {
      //MODAL RETURN CASH
      if (
        !transactionToDeleteItem.ticketData.player_id ||
        !transactionToDeleteItem.ticketData.username ||
        !transactionToDeleteItem.ticketData.pin
      ) {
        toast.error(t("error.error"));
        return;
      }

      var data = {
        operator: operator,
        bingoName: hall,
        session: sessionRef.current,
        sessionID: sessionRef.current,
        provider: "CH",
        description: "Avbryt Fylle på",
        amount: parseInt(transactionToDeleteItem.amount) * -1,

        ticketID: transactionToDeleteItem.ticketData.player_id,
        ticketUsername: transactionToDeleteItem.ticketData.username,
        ticket7Pincode: transactionToDeleteItem.ticketData.pin,
        cashoutType: "cash_from_wallet",
        playerID: transactionToDeleteItem.ticketData.player_id,
        useSMS: false,
        action: "fyllePaCHCancel",
        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
      };

      socket.emit("fyllePaCHCancel", data);

      //redifineTransactions(transactionToDelete);
      //removeTicketsCH();

      return;
    }

    if (transactionToDeleteItem.name === "Ny Billett - EBS") {
      //MODAL RETURN CASH

      if (!transactionToDeleteItem.ticketData.id) {
        toast.error(t("error.error"));
        return;
      }

      socket.emit("cancelCashinEBS", {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(transactionToDeleteItem.amount),
        provider: "EBS",
        description: "Avbryt Ny Billett",
        ebsID: transactionToDeleteItem.ticketData.id,
        action: "cancelCashinEBS",
      });

      //redifineTransactions(transactionToDelete);
      //removeTicketsCH();

      return;
    }

    if (transactionToDeleteItem.name === "Utbetaling - EBS") {
      //MODAL RETURN CARD PAYMENT

      if (!transactionToDeleteItem.ticketData.amount) {
        toast.error(t("error.error"));
        return;
      }

      socket.emit("cancelCashoutEBS", {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(transactionToDeleteItem.ticketData.amount),
        provider: "EBS",
        description: "Avbryt Utbetaling",
        action: "cancelCashoutEBS",
      });
      //redifineTransactions(transactionToDelete);
      //removeTicketsCH();

      return;
    }

    if (transactionToDeleteItem.name === "Fylle på - EBS") {
      //MODAL RETURN CASH
      if (!transactionToDeleteItem.ticketData.id) {
        toast.error(t("error.error"));
        return;
      }

      socket.emit("cancelRefillEBS", {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(transactionToDeleteItem.amount),
        provider: "EBS",
        description: "Avbryt Fylle på",
        ebsID: transactionToDeleteItem.ticketData.id,
        action: "cancelRefillEBS",
      });

      //redifineTransactions(transactionToDelete);
      //removeTicketsCH();

      return;
    }

    if (transactionToDeleteItem.name === "Betal med kort") {
      //MODAL RETURN CASH
      return;
    }

    if (transactionToDeleteItem.name === "Betal med cash") {
      //MODAL RETURN CASH
      return;
    }

    if (transactionToDeleteItem.name === "Betal med konto") {
      //MODAL RETURN CASH
      return;
    }

    if (transactionToDeleteItem.name === "Kontant uttak") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "",
        "Avbryt Kontant uttak"
      );

      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Ny Billett - OK D.") {
      //MODAL RETURN CASH
      if (!isOKBingoEnabled) {
        cancelExternalProvider(
          transactionToDeleteItem,
          "OK D.",
          "Avbryt Ny Billett"
        );

        setTimeout(() => {
          redifineTransactions();
        }, 200);
        return;
      }

      console.log("ticketToDelete", transactionToDeleteItem);
      setAmount(transactionToDeleteItem.amount);
      updateIsTerminalBusy(true);
      updateIsLoadingScreen(true);

      socket.emit("cancelCashinOK", {
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        userInfo: parentInfo ? parentInfo : userInfo,
        amount: parseInt(transactionToDeleteItem.amount),
        provider: "OK D.",
        description: "Avbryt Ny Billett",
        okID: transactionToDeleteItem.ticketID,
        action: "cancelCashinOK",
        printer: printer,
        OKBingoID: OKBingoIDRef.current,
      });

      return;
    }

    if (transactionToDeleteItem.name === "Utbetaling - OK D.") {
      //MODAL RETURN CARD PAYMENT
      if (!isOKBingoEnabled) {
        cancelExternalProvider(
          transactionToDeleteItem,
          "OK D.",
          "Avbryt Utbetaling"
        );
        setTimeout(() => {
          redifineTransactions();
        }, 200);
        return;
      }

      return;
    }

    if (transactionToDeleteItem.name === "Fylle på - OK D.") {
      //MODAL RETURN CASH
      if (!isOKBingoEnabled) {
        cancelExternalProvider(
          transactionToDeleteItem,
          "OK D.",
          "Avbryt Fylle på"
        );
        setTimeout(() => {
          redifineTransactions();
        }, 200);
        return;
      }

      return;
    }

    if (transactionToDeleteItem.name === "Ny Billett - WG") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "WG",
        "Avbryt Ny Billett"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Utbetaling - WG") {
      //MODAL RETURN CARD PAYMENT
      cancelExternalProvider(
        transactionToDeleteItem,
        "WG",
        "Avbryt Utbetaling"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Inn - NT") {
      //MODAL RETURN CASH
      cancelExternalProvider(transactionToDeleteItem, "NT", "Avbryt Inn");
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Ut - NT") {
      //MODAL RETURN CARD PAYMENT
      cancelExternalProvider(transactionToDeleteItem, "NT", "Avbryt Ut");
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Brus Liten") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Brus Liten"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Rett I Koppen") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Rett I Koppen"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Tobakk") {
      //MODAL RETURN CASH
      cancelExternalProvider(transactionToDeleteItem, "Kiosk", "Avbryt Tobakk");
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Lighter") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Lighter"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Lefse Liten") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Lefse Liten"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Pølse") {
      //MODAL RETURN CASH
      cancelExternalProvider(transactionToDeleteItem, "Kiosk", "Avbryt Pølse");
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Brus") {
      //MODAL RETURN CASH
      cancelExternalProvider(transactionToDeleteItem, "Kiosk", "Avbryt Brus");
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Toast") {
      //MODAL RETURN CASH
      cancelExternalProvider(transactionToDeleteItem, "Kiosk", "Avbryt Toast");
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Melkesjokolade") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Melkesjokolade"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Kvikk Lunsj") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Kvikk Lunsj"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }

    if (transactionToDeleteItem.name === "Brus Utgått") {
      //MODAL RETURN CASH
      cancelExternalProvider(
        transactionToDeleteItem,
        "Kiosk",
        "Avbryt Brus Utgått"
      );
      setTimeout(() => {
        redifineTransactions();
      }, 200);

      return;
    }
  };

  // * Cancel transaction and add it to the list
  const redifineTransactions = async () => {
    if (parentInfo) {
      socket.emit("checkLimitStatus", parentInfo);
    } else {
      socket.emit("checkLimitStatus", userInfo);
    }

    const modifiedTransactions = transactionsRef.current.map((transaction) =>
      transaction === transactionToDeleteRef.current
        ? { ...transaction, isDeleted: true }
        : transaction
    );
    deleteTransactions(modifiedTransactions);

    setTimeout(() => {
      addCancelTransaction(transactionToDeleteRef.current, -1);
    }, 100);

    setTimeout(() => {
      setTransactionToDelete({});
    }, 600);
  };

  //* Just write the transaction in the backend
  const cancelExternalProvider = (
    transactionToDelete,
    providerToDelete,
    descriptionToDelete
  ) => {
    var data = {
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: providerToDelete,
      description: descriptionToDelete,
      amount: parseInt(transactionToDelete.amount) * -1,
      ticketID: 0,
      ticketUsername: "",
      ticket7Pincode: "",
      action: "newTransaction",
      user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
      userInfo: !parentInfo ? userInfo : parentInfo,
    };

    socket.emit("newTransaction", data);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginBottom: "15px",
        height: "calc(100% - 250px)",
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
        backgroundColor: "#15192b",
        borderRadius: "15px",
        padding: "15px",
      }}
    >
      {/*Member TRANSACTIONS CONTAINER */}
      <Grid
        container
        sx={{
          height: "calc(100% - 150px)",
          width: "100%",
          display: "flex",
          alignContent: "flex-start",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            height: "35px",
            width: "100%",
            padding: "0px 20px",
            marginTop: "-15px",
            marginBottom: "25px",
          }}
        >
          <h2> {t("sales.transactions")}</h2>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            height: "calc(100% - 120px)",
            width: "100%",
          }}
        >
          {/*Transaction List*/}
          <Grid
            item
            xs={12}
            sx={{
              height: "calc(100% - 120px)",
              width: "100%",
            }}
          >
            {/*Transaction List*/}
            <Grid
              container
              sx={{
                height: "calc(100% - 0px)",
                width: "100%",
                display: "flex",
                alignContent: "flex-start",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto",
                padding: "0px 25px",
                paddingRight: "45px",
              }}
              className="hide-scrollbar"
            >
              {transactions.map((transaction, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "20px 0",
                      textDecoration: transaction.isDeleted
                        ? "line-through"
                        : "",
                    }}
                    key={index}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <p style={{ margin: 0 }}>{transaction.name}</p>
                    </div>
                    <div style={{ textAlign: "right", width: "100px" }}>
                      <p style={{ margin: 0 }}>{transaction.amount}</p>
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!transaction.isDeleted &&
                      !transaction.isCancel &&
                      transaction.name !== "Ny Billett - OK D." &&
                      transaction.name !== "Utbetaling - OK D." &&
                      transaction.name !== "Fylle på - OK D." &&
                      transaction.name !== "Betal med kort" &&
                      transaction.name !== "Betal med Spillerkonto" &&
                      transaction.name !== "Betal med cash" ? (
                        <CloseIcon
                          onClick={() => {
                            handleDelete(transaction);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <CloseIcon style={{ color: "transparent" }} />
                      )}
                    </div>
                  </Grid>
                );
              })}
            </Grid>

            {/*Total's */}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                borderTop: "5px solid black",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: "20px",
                  paddingRight: "35px",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    lineHeight: "0.3",
                  }}
                >
                  <p>{t("sales.print")}</p>
                  <Checkbox
                    checked={print}
                    onChange={() => {
                      updatePrint(!print);
                    }}
                    sx={{
                      padding: "9px 0 9px 9px",
                      marginRight: "-8px",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    lineHeight: "0.3",
                  }}
                >
                  <p>{t("sales.total")}</p>
                  <p> {parseInt(total)}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    lineHeight: "0.3",
                  }}
                >
                  <p>{t("sales.payCard")}</p>
                  <p> {parseInt(payCard) + parseInt(payCardTemp)}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    lineHeight: "0.3",
                  }}
                >
                  <p>{t("sales.payCash")}</p>
                  <p> {parseInt(payCash) + parseInt(payCashTemp)}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    lineHeight: "0.3",
                  }}
                >
                  <p>{t("sales.payAccount")}</p>
                  <p>
                    {parseInt(paySpillerkonto) + parseInt(paySpillerkontoTemp)}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    lineHeight: "0.3",
                  }}
                >
                  <p>
                    {total -
                      (payCash + payCashTemp) -
                      (payCard + payCardTemp) -
                      (paySpillerkonto + paySpillerkontoTemp) -
                      withdraw >
                    0
                      ? t("sales.remaining")
                      : t("sales.change")}
                  </p>
                  <p>
                    {total -
                      (payCash + payCashTemp) -
                      (payCard + payCardTemp) -
                      (paySpillerkonto + paySpillerkontoTemp) -
                      withdraw >
                    0
                      ? total -
                        (payCash + payCashTemp) -
                        (payCard + payCardTemp) -
                        (paySpillerkonto + paySpillerkontoTemp) -
                        withdraw
                      : change}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TransactionList;
