import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";
import { Skeleton } from "@mui/material";

function ModalDifferentParentOK() {
  const { t } = useTranslation();

  const {
    OKBingoID,
    updateTransactions,
    updateIsLimitSurpassed,
    updateShowIsLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    updateMaxAllowed,
    updateShowIsLimitSurpassedTransaction,
    updateIsDailyLimitSurpassed,
    updateCurrentTicketOK,
    printer,
    updateIsTerminalBusy,
    updateIsLoadingScreen,
    adoptedTicket,
    parentInfo,
    updateAdoptedTicket,
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    openSessionTerminal,
    updateOpenSessionTerminal,
    updateShowModalDifferentParentOK,
    updateTransactionsArray,
    ticketsOK,
    currentTicketOK,
    updateTicketsOK,
    updateTicketsOKWithNewSaldo,
  } = useContext(AppContext);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  const [isTerminalBusy, setIsTerminalBusy] = useState(false);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(0);
  const [ticket, setTicket] = useState({});
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    console.log("loading status", loading);
  }, [loading]);

  //* REFS
  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const adoptedTicketRef = useRef(adoptedTicket);
  useEffect(() => {
    adoptedTicketRef.current = adoptedTicket;
  }, [adoptedTicket]);

  const amountRef = useRef(amount);
  useEffect(() => {
    console.log("NEW AMOUNT", amount);
    amountRef.current = amount;
  }, [amount]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);

  const [okTicketID, setOkTicketID] = useState(false);
  const okTicketIDRef = useRef(okTicketID);
  useEffect(() => {
    console.log("NEW OK TICKET", okTicketID);
    okTicketIDRef.current = okTicketID;
  }, [okTicketID]);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  //* Recover all the info
  useEffect(() => {
    socket.emit("ticketsOK", {
      operator: operator,
      bingoName: hall,
      userID: adoptedTicket.id,
      userInfo: adoptedTicket,
      session: session,
      action: "ticketsOK",
      OKBingoID: OKBingoID,
    });
  }, []);

  useEffect(() => {
    console.log("ticketsOK", ticketsOK);
    if (ticketsOK.length > 0) {
      for (let i = 0; i < ticketsOK.length; i++) {
        if (
          ticketsOK[i].okID === currentTicketOK.okID &&
          ticketsOK[i].isClosed
        ) {
          setLoading(false);
          setIsClosed(true);
        }
      }
    }
  }, [ticketsOK]);

  useEffect(() => {
    console.log("currentTicketOK", currentTicketOK);
  }, [currentTicketOK]);

  useEffect(() => {
    const handleTicketsOK = (data) => {
      if (data.error) {
        toast.error(t("error.error"));
        return;
      }

      updateTicketsOK(data.ticketsOK);
    };

    const handleBroadcastGetTicketSaldoOK = (data) => {
      console.log(
        "broadcastGetTicketSaldoOK",
        data,
        "currentTicketOK",
        currentTicketOK
      );
      if (data.error) {
        toast.error(t("error.error"));
        return;
      }
      updateTicketsOKWithNewSaldo(data);

      if (data.ticketNumber === currentTicketOK.okID) {
        console.log("SET FALSE");
        setAmount(data.newSaldo);
        setLoading(false);
        setTicket(data);
      }
    };

    const handleCashoutOK = (data) => {
      console.log("handleCashoutOK", data);
      if (!data) {
        toast.error(t("error.error"));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }
      setOkTicketID(data.ticketID);
    };

    const handleBroadcastCashoutOK = (data) => {
      console.log("handleBroadcastCashoutOK", data);

      if (data.errorNumber !== "") {
        toast.error(data.errorDescription);
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        return;
      }

      if (data.ticketID === okTicketIDRef.current) {
        socket.emit("registerTransactionCashoutOK", {
          operator: operatorRef.current,
          bingoName: hallRef.current,
          sessionID: sessionRef.current,
          userInfo: adoptedTicketRef.current,
          amount: parseInt(data.amount),
          provider: "OK D.",
          description: "Utbetaling",
          okID: data.ticketNumber,
          action: "registerTransactionCashoutOK",
          printer: printerRef.current,
          parameter: data,
          OKBingoID: OKBingoIDRef.current,
        });
      }
    };

    const handleRegisterTransactionCashoutOK = (data) => {
      console.log("handleRegisterTransactionCashinOK", data);
      if (!data) {
        toast.error(t("error.error"));
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        return;
      }

      if (data.maxAllowed) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);
        updateMaxAllowed(data.maxAllowed);
        updateShowIsLimitSurpassedTransaction(true);

        return;
      }

      if (data.maxAllowed === 0) {
        updateIsTerminalBusy(false);
        updateIsLoadingScreen(false);

        if (data.isMonthly) {
          updateIsMonthlyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
        if (data.isDaily) {
          updateIsDailyLimitSurpassed(true);
          updateIsLimitSurpassed(true);
          updateShowIsLimitSurpassed(true);
          return;
        }
      }

      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      updateTransactions({
        name: "Utbetaling" + " - " + "OK D.",
        amount: parseInt(amountRef.current) * -1,
        type: "transaction",
        ticketData: data,
      });

      updateCurrentTicketOK(false);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateShowModalDifferentParentOK(false);
        updateIsTerminalBusy(false);
      }, 300);
    };

    socket.on("broadcastGetTicketSaldoOK", handleBroadcastGetTicketSaldoOK);
    socket.on("ticketsOK", handleTicketsOK);
    socket.on("cashoutOK", handleCashoutOK);
    socket.on("broadcastCashoutOK", handleBroadcastCashoutOK);
    socket.on(
      "registerTransactionCashoutOK",
      handleRegisterTransactionCashoutOK
    );

    return () => {
      socket.off("broadcastGetTicketSaldoOK", handleBroadcastGetTicketSaldoOK);
      socket.off("ticketsOK", handleTicketsOK);
      socket.off("cashoutOK", handleCashoutOK);
      socket.off("broadcastCashoutOK", handleBroadcastCashoutOK);
      socket.off(
        "registerTransactionCashoutOK",
        handleRegisterTransactionCashoutOK
      );
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handlePayout = async () => {
    if (isTerminalBusy) {
      return;
    }
    updateFocusOnMainScreen(true);

    setIsTerminalBusy(true);

    updateIsTerminalBusy(true);
    updateIsLoadingScreen(true);

    if (isTerminalBusy) {
      return;
    }
    socket.emit("cashoutOK", {
      operator: operatorRef.current,
      bingoName: hallRef.current,
      sessionID: sessionRef.current,
      userInfo: adoptedTicketRef.current,
      amount: parseInt(amountRef.current),
      provider: "OK D.",
      description: "Utbetaling",
      okID: currentTicketOK.okID,
      action: "cashoutOK",
      printer: printer,
      OKBingoID: OKBingoIDRef.current,
    });
  };

  const cancel = async () => {
    updateOpenSessionTerminal(false);
    updateAdoptedTicket({});
    updateFocusOnMainScreen(true);
    updateShowModalDifferentParentOK(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("sales.ticketWithDifferentParentTitle")} </h1>

            {!loading ? (
              <>
                {isClosed && (
                  <p
                    style={{
                      fontSize: "22px",
                      color: "#42a5f5",
                      fontWeight: "bold",
                    }}
                  >
                    {t("sales.ticketClosedOK")}.
                  </p>
                )}

                {ticket.errorDescription === "" && !ticket.isClosed && (
                  <>
                    <p>{t("sales.ticketWithDifferentParentText")}</p>
                    <p>
                      {t("sales.currentSaldo")}:{" "}
                      <span
                        style={{
                          fontSize: "22px",
                          color: "#42a5f5",
                          fontWeight: "bold",
                        }}
                      >
                        {amount + " kr."}
                      </span>
                    </p>
                  </>
                )}

                {ticket.errorDescription === "Ticket er allerede lukket" ||
                  (ticket.errorDescription === "Ticket er allerede lukket" && (
                    <p
                      style={{
                        fontSize: "22px",
                        color: "#42a5f5",
                        fontWeight: "bold",
                      }}
                    >
                      {ticket.errorDescription}.
                    </p>
                  ))}
              </>
            ) : (
              <>
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  style={{
                    height: "75px",
                  }}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  style={{
                    height: "75px",
                  }}
                />
                <Skeleton
                  sx={{ bgcolor: "grey.500" }}
                  style={{
                    height: "175px",
                  }}
                />
              </>
            )}

            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: "55px" }}
            >
              {ticket.errorDescription === "" && (
                <button
                  className="btn-custom-yes"
                  style={{
                    opacity: loading ? 0.5 : 1,
                  }}
                  onClick={() => {
                    if (loading) {
                      return;
                    }
                    handlePayout();
                  }}
                >
                  {t("sales.confirm")}
                </button>
              )}

              <button
                className="btn-custom-no"
                style={{
                  opacity: loading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  cancel();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDifferentParentOK;
