import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Box, TextField, Typography, Button } from "@mui/material";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import Calculator from "../../../components/Calculator/Calculator";
import { SocketContext } from "../../../context/SocketContext";
import toast, { Toaster } from "react-hot-toast";

import {
  MultipleStop as MultipleStopIcon,
  Payment as PaymentIcon,
  AddCard as AddCardIcon,
} from "@mui/icons-material";

import ClubHouseCard from "../../../assets/img/ch-card.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";

function ModalSpillerkonto() {
  const { t } = useTranslation();

  const {
    updateShowSpillerkonto,
    updatePayCashTemp,
    userInfo,
    updateTransactions,
    currentUser,
    hall,
    operator,
    session,
    updateIsWaitingForUser,
    parentInfo,
    isFullUser,
    updateRefreshUser,
    receiptToPrint,
    userLimits,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    updatePayCashTemp(0);
    updateRefreshUser(true);
  }, []);

  const [isFocusedAmountDeposit, setIsFocusedAmountDeposit] = useState(true);
  const [isFocusedAmountWithdraw, setIsFocusedAmountWithdraw] = useState(false);

  useEffect(() => {
    if (userLimits.isDaily || userLimits.isMonthly) {
      setIsFocusedAmountDeposit(false);
      setIsFocusedAmountWithdraw(true);
    }
  }, [userLimits]);

  const receiptToPrintRef = useRef(receiptToPrint);
  useEffect(() => {
    receiptToPrintRef.current = receiptToPrint;
  }, [receiptToPrint]);

  const [amountDeposit, setAmountDeposit] = useState(0);
  const amountDepositRef = useRef(amountDeposit);
  useEffect(() => {
    amountDepositRef.current = amountDeposit;
  }, [amountDeposit]);

  const [amountWithdraw, setAmountWithdraw] = useState(0);
  const amountWithdrawRef = useRef(amountWithdraw);
  useEffect(() => {
    amountWithdrawRef.current = amountWithdraw;
  }, [amountWithdraw]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const onCloseRef = useRef(null);

  //var isBusy = false;
  const isBusy = useRef(false);

  useEffect(() => {
    const handleCashin = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      updateTransactions({
        name: "Sett inn - CH",
        amount: amountDepositRef.current,
        type: "transaction",
      });

      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      setTimeout(() => {
        updateRefreshUser(true);
      }, 500);
      updateShowSpillerkonto(false);
    };

    const handleCashout = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }
      updateTransactions({
        name: "Ta ut - CH",
        amount: parseInt(amountWithdrawRef.current) * -1,
        type: "transaction",
      });

      if (parentInfo) {
        socket.emit("checkLimitStatus", parentInfo);
      } else {
        socket.emit("checkLimitStatus", userInfo);
      }

      setTimeout(() => {
        updateRefreshUser(true);
      }, 500);

      //Close the pincode modal
      if (onCloseRef.current) {
        onCloseRef.current();
        onCloseRef.current = null;
        updateShowSpillerkonto(false);
      }
      updateShowSpillerkonto(false);
    };

    const handleSendPincodeTerminal = (data) => {
      var currentPincode = 0;
      if (parentInfo) {
        currentPincode = parentInfo.PIN2;
      } else {
        currentPincode = userInfo.PIN2;
      }

      if (!isBusy.current && currentPincode !== data.pincode) {
        isBusy.current = true;

        toast.error(t("error.errorCode"));
        socket.emit("activateSMS", {
          bingoName: hallRef.current,
        });

        setTimeout(() => {
          isBusy.current = false;
        }, 3000);
        return;
      }

      if (!isBusy.current && currentPincode === data.pincode) {
        socket.emit("deactivateSMS", {
          bingoName: hallRef.current,
        });
        isBusy.current = true;

        /*
        updateTransactions({
          name: "Ta ut - CH",
          amount: parseInt(amountWithdrawRef.current) * -1,
          type: "transaction",
        });
        */

        var dataToSend = {
          playerID: !parentInfo ? userInfo.id : parentInfo.id,
          amount: parseInt(amountWithdrawRef.current),
          usePrinter: false,
          useSMS: false,
          printBarcode: false,
          qrMessage: false,
          session: session,
          action: "cashout",
          userInfo: !parentInfo ? userInfo : parentInfo,
          cashoutType: "cash_from_wallet",
          dataToSaveTransaction: {
            operator: operator,
            bingoName: hall,
            sessionID: session,
            provider: "CH",
            description: "Ta ut",
            amount: parseInt(amountWithdrawRef.current) * -1,
            user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
            userInfo: !parentInfo ? userInfo : parentInfo,
            action: "newTransaction",
            ticketID: userInfo.id,
            ticketUsername: userInfo.username,
            ticket7Pincode: currentUser,
            netsTicket: receiptToPrintRef.current,
          },
        };
        if (dataToSend.amount === 0) {
          setTimeout(() => {
            isBusy.current = false;
          }, 3000);
          return;
        }
        socket.emit("cashout", dataToSend);

        /*
        if (onCloseRef.current) {
          onCloseRef.current();
          onCloseRef.current = null;
          updateShowSpillerkonto(false);
        }
        */

        setTimeout(() => {
          isBusy.current = false;
        }, 3000);
      }
    };

    socket.on("cashin", handleCashin);
    socket.on("cashout", handleCashout);
    socket.on("sendPincodeTerminal", handleSendPincodeTerminal);

    return () => {
      socket.off("cashin", handleCashin);
      socket.off("cashout", handleCashout);
      socket.off("sendPincodeTerminal", handleSendPincodeTerminal);
    };
  }, [socket]);

  /*FOCUS AND INPUT VALUES*/
  const amountDepositFocus = () => {
    if (userLimits.isDaily || userLimits.isMonthly) {
      return;
    }
    setIsFocusedAmountDeposit(true);
    setIsFocusedAmountWithdraw(false);
    setAmountWithdraw(0);
  };

  const amountWithdrawFocus = () => {
    setIsFocusedAmountDeposit(false);
    setIsFocusedAmountWithdraw(true);
    setAmountDeposit(0);
  };

  const updateAmountDeposit = (value, isTotal) => {
    if (
      (amountDeposit === 0 || amountDeposit === "0") &&
      (value === "0" || value === "00")
    ) {
      return;
    }

    if (
      (amountDeposit === "0" || amountDeposit === 0) &&
      value !== "backspace"
    ) {
      setAmountDeposit(value);
      return;
    }
    if (
      (amountDeposit === "0" || amountDeposit === 0) &&
      value === "backspace"
    ) {
      return;
    }
    if (
      value === "backspace" &&
      amountDeposit.toString().length === 1 &&
      (amountDeposit !== "0" || amountDeposit !== 0)
    ) {
      setAmountDeposit("0");
      return;
    }
    if (
      value === "backspace" &&
      amountDeposit.toString().length > 1 &&
      (amountDeposit !== "0" || amountDeposit !== 0)
    ) {
      setAmountDeposit(
        amountDeposit
          .toString()
          .substring(0, amountDeposit.toString().length - 1)
      );

      return;
    }

    if (isTotal) {
      setAmountDeposit(value.toString());
      return;
    }

    setAmountDeposit(amountDeposit + value);
  };

  const updateAmountWithdraw = (value, isTotal) => {
    if (
      (amountWithdraw === 0 || amountWithdraw === "0") &&
      (value === "0" || value === "00")
    ) {
      return;
    }

    if (
      (amountWithdraw === "0" || amountWithdraw === 0) &&
      value !== "backspace"
    ) {
      setAmountWithdraw(value);
      return;
    }
    if (
      (amountWithdraw === "0" || amountWithdraw === 0) &&
      value === "backspace"
    ) {
      return;
    }
    if (
      value === "backspace" &&
      amountWithdraw.toString().length === 1 &&
      (amountWithdraw !== "0" || amountWithdraw !== 0)
    ) {
      setAmountWithdraw("0");
      return;
    }
    if (
      value === "backspace" &&
      amountWithdraw.toString().length > 1 &&
      (amountWithdraw !== "0" || amountWithdraw !== 0)
    ) {
      setAmountWithdraw(
        amountWithdraw
          .toString()
          .substring(0, amountWithdraw.toString().length - 1)
      );

      return;
    }

    if (isTotal) {
      setAmountWithdraw(value.toString());
      return;
    }

    setAmountWithdraw(amountWithdraw + value);
  };
  /*END FOCUS AND INPUT VALUES*/

  const finishTransaction = () => {
    //Cashout
    if (isFocusedAmountWithdraw) {
      //Call for pin
      modalEnd();

      return;
    }

    //Cashin
    /*
    updateTransactions({
      name: isFocusedAmountDeposit ? "Sett inn - CH" : "Ta ut - CH",
      amount: isFocusedAmountDeposit
        ? parseInt(amountDeposit)
        : parseInt(amountWithdraw),
      type: "transaction",
    });
    */

    var dataToSend = {
      playerID: parentInfo ? parentInfo.id : userInfo.id,
      amount: parseInt(amountDeposit),
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      action: "cashin",
      userInfo: !parentInfo ? userInfo : parentInfo,
      isChild: false,
      dataToSaveTransaction: {
        operator: operator,
        bingoName: hall,
        sessionID: session,
        provider: "CH",
        description: "Sett inn",
        amount: parseInt(amountDeposit),
        user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
        userInfo: !parentInfo ? userInfo : parentInfo,
        action: "newTransaction",
        ticketID: userInfo.id,
        ticketUsername: userInfo.username,
        ticket7Pincode: currentUser,
        netsTicket: receiptToPrintRef.current,
      },
    };

    socket.emit("cashin", dataToSend);
  };

  const modalEnd = () => {
    updateIsWaitingForUser(true);
    socket.emit("activateSMS", {
      bingoName: hallRef.current,
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        onCloseRef.current = onClose;

        return (
          <div
            className="react-confirm-alert-body react-confirm-alert"
            style={{ width: "600px", zIndex: "999999", position: "relative" }}
          >
            <h1>{t("sales.pincodeTitle")}</h1>
            <p>{t("sales.pincodeText1")}</p>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-no"
                onClick={() => {
                  socket.emit("deactivateSMS", {
                    bingoName: hallRef.current,
                  });
                  onClose();
                  updateIsWaitingForUser(false);
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="modal-overlay">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: "9999999 !important",
            fontSize: "24px",
          },
        }}
        containerStyle={{
          zIndex: 99999999, // Ensure this value is higher than the modal's z-index
        }}
      />
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div
            className="custom-modal-2"
            style={{
              background: "white",
              borderRadius: "40px",
              width: "1165px",
              height: "839px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
              }}
            >
              {/*HEADER*/}
              <Grid
                container
                sx={{
                  height: "100%",
                  width: "100%",
                  marginTop: 0,
                  marginLeft: 0,
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "10px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Grid
                      container
                      flexDirection="row"
                      sx={{
                        height: "100%",
                        width: "100%",
                        marginTop: 0,
                        marginLeft: 0,
                        justifyContent: "center",
                        alignContent: "center",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      {/* TITLE */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          padding: "10px",
                          height: "75px",
                          width: "100%",
                          marginTop: 0,
                          marginLeft: 0,
                          justifyContent: "flex-start",
                          alignContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <h2
                          style={{
                            color: "#464646",
                          }}
                        >
                          {t("sales.spillerkonto")}
                        </h2>
                      </Grid>

                      {/* CARD LOGO */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          padding: "10px",
                          height: "150px",
                          width: "100%",
                          marginTop: 0,
                          marginLeft: 0,
                          justifyContent: "flex-start",
                          alignContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "150px",
                            width: "auto",
                            paddingTop: 0,
                          }}
                          alt=""
                          src={ClubHouseCard}
                        />
                      </Grid>

                      {/* INPUTS */}
                      <Grid
                        container
                        flexDirection="row"
                        sx={{
                          height: "calc(100% - 405px)",
                          width: "100%",
                          alignContent: "space-around",
                        }}
                      >
                        {/*Input*/}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            height: "100px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: "center",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            opacity: isFocusedAmountDeposit ? "1" : "0.25",
                          }}
                        >
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: "100%",
                              width: "100%",
                              marginTop: 0,
                              marginLeft: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{
                                padding: "10px",
                                display: "flex", // Use flex display for alignment
                                alignItems: "center", // Vertically center the contents
                                justifyContent: "center", // Horizontally center the contents
                                height: "75px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {/* Container for icon and text */}
                                <AddCardIcon
                                  sx={{
                                    fontSize: "45px",
                                    marginRight: "10px", // Adjust spacing between icon and text
                                    color: "#464646",
                                  }}
                                />
                                <div>
                                  {" "}
                                  {/* Container for the text */}
                                  <Typography
                                    sx={{
                                      fontSize: "19px",
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      color: "#464646",
                                    }}
                                  >
                                    {t("sales.deposit")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px", // Smaller font size for the second line
                                      color: "#464646",
                                    }}
                                  >
                                    {t("sales.depositSubtitle")}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              sx={{
                                marginTop: 0,
                                padding: "10px",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TextField
                                onFocus={() => amountDepositFocus()}
                                autoComplete="off"
                                value={
                                  amountDeposit === 0 || amountDeposit === "0"
                                    ? ""
                                    : amountDeposit
                                }
                                sx={{
                                  "& label": {
                                    position: "relative !important",
                                    fontSize: "22px !important",
                                    color: "#464646 !important",
                                  },
                                  "& label.Mui-focused": {
                                    position: "relative !important",
                                    color: "#464646 !important",
                                  },
                                  "& input": {
                                    color: "#464646 !important",
                                    border: "3px solid #464646",
                                    borderRadius: "4px",
                                    height: "50px !important",
                                    width: "275px !important",
                                    fontSize: "40px !important",
                                    textAlign: "center",
                                  },
                                  "& input.Mui-focused": {
                                    color: "#464646 !important",
                                    border: "3px solid #464646",
                                    borderRadius: "4px",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent", // Change the border color or set to 'transparent'
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    caretColor: "transparent", // Makes the cursor transparent
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/*Input*/}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            height: "100px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: "center",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            opacity: isFocusedAmountWithdraw ? "1" : "0.25",
                          }}
                        >
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: "100%",
                              width: "100%",
                              marginTop: 0,
                              marginLeft: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{
                                padding: "10px",
                                display: "flex", // Use flex display for alignment
                                alignItems: "center", // Vertically center the contents
                                justifyContent: "center", // Horizontally center the contents
                                height: "75px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {/* Container for icon and text */}
                                <PaymentIcon
                                  sx={{
                                    fontSize: "45px",
                                    marginRight: "10px", // Adjust spacing between icon and text
                                    color: "#464646",
                                  }}
                                />
                                <div>
                                  {" "}
                                  {/* Container for the text */}
                                  <Typography
                                    sx={{
                                      fontSize: "19px",
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      color: "#464646",
                                    }}
                                  >
                                    {t("sales.withdraw")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px", // Smaller font size for the second line
                                      color: "#464646",
                                    }}
                                  >
                                    {t("sales.withdrawSubtitle")}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              sx={{
                                marginTop: 0,
                                padding: "10px",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TextField
                                onFocus={() => amountWithdrawFocus()}
                                autoComplete="off"
                                value={
                                  amountWithdraw === 0 || amountWithdraw === "0"
                                    ? ""
                                    : amountWithdraw
                                }
                                sx={{
                                  "& label": {
                                    position: "relative !important",
                                    fontSize: "22px !important",
                                    color: "#464646 !important",
                                  },
                                  "& label.Mui-focused": {
                                    position: "relative !important",
                                    color: "#464646 !important",
                                  },
                                  "& input": {
                                    color: "#464646 !important",
                                    border: "3px solid #464646",
                                    borderRadius: "4px",
                                    height: "50px !important",
                                    width: "275px !important",
                                    fontSize: "40px !important",
                                    textAlign: "center",
                                  },
                                  "& input.Mui-focused": {
                                    color: "#464646 !important",
                                    border: "3px solid #464646",
                                    borderRadius: "4px",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent", // Change the border color or set to 'transparent'
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    caretColor: "transparent", // Makes the cursor transparent
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/*Input*/}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            height: "100px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: "center",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            opacity: "0.25",
                          }}
                        >
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: "100%",
                              width: "100%",
                              marginTop: 0,
                              marginLeft: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{
                                padding: "10px",
                                display: "flex", // Use flex display for alignment
                                alignItems: "center", // Vertically center the contents
                                justifyContent: "center", // Horizontally center the contents
                                height: "75px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {/* Container for icon and text */}
                                <MultipleStopIcon
                                  sx={{
                                    fontSize: "45px",
                                    marginRight: "10px", // Adjust spacing between icon and text
                                    color: "#464646",
                                  }}
                                />
                                <div>
                                  {" "}
                                  {/* Container for the text */}
                                  <Typography
                                    sx={{
                                      fontSize: "19px",
                                      fontWeight: "bold",
                                      textTransform: "uppercase",
                                      color: "#464646",
                                    }}
                                  >
                                    {t("sales.transfer")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "15px", // Smaller font size for the second line
                                      color: "#464646",
                                    }}
                                  >
                                    {t("sales.transferSubtitle")}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              sx={{
                                marginTop: 0,
                                padding: "10px",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TextField
                                onFocus={() => amountDepositFocus()}
                                autoComplete="off"
                                disabled
                                value={""}
                                sx={{
                                  "& label": {
                                    position: "relative !important",
                                    fontSize: "22px !important",
                                    color: "#464646 !important",
                                  },
                                  "& label.Mui-focused": {
                                    position: "relative !important",
                                    color: "#464646 !important",
                                  },
                                  "& input": {
                                    color: "#464646 !important",
                                    border: "3px solid #464646",
                                    borderRadius: "4px",
                                    height: "50px !important",
                                    width: "275px !important",
                                    fontSize: "40px !important",
                                    textAlign: "center",
                                  },
                                  "& input.Mui-focused": {
                                    color: "#464646 !important",
                                    border: "3px solid #464646",
                                    borderRadius: "4px",
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent", // Change the border color or set to 'transparent'
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    caretColor: "transparent", // Makes the cursor transparent
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* PRINT MOVEMENT */}
                      <Grid
                        container
                        flexDirection="row"
                        sx={{
                          height: "calc(155px)",
                          width: "100%",
                          alignContent: "space-around",
                        }}
                      >
                        <Grid
                          container
                          flexDirection="row"
                          sx={{
                            height: "125px",
                            width: "100%",
                            marginTop: 0,
                            marginLeft: 0,
                            padding: "10px",
                          }}
                        >
                          {/*Print Button*/}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                width: "200px",
                                height: "90px",
                                backgroundColor: false ? "#009f83" : "grey",
                                opacity: false ? "1" : "0.45",
                                color: "white",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                              }}
                              onClick={() => {}}
                            >
                              {t("sales.printMovement")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: "10px",
                    }}
                  >
                    {/* SUBTITLE SALDO */}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        padding: "10px",
                        height: "35px",
                        width: "100%",
                        marginTop: 0,
                        marginLeft: 0,
                        justifyContent: "flex-start",
                        alignContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#464646",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        {!isFullUser
                          ? t("sales.saldoUserAccount") +
                            " " +
                            parentInfo.balances.wallet
                          : t("sales.saldoUserAccount") +
                            " " +
                            userInfo.balances.wallet}
                      </p>
                    </Grid>

                    <Calculator
                      updateAmount={
                        isFocusedAmountDeposit
                          ? updateAmountDeposit
                          : updateAmountWithdraw
                      }
                      isColorCard={true}
                    />
                    <Grid
                      item
                      xs={12}
                      sx={{
                        width: "100%",
                        maxWidth: "100% !important",
                        height: "100%",
                      }}
                    >
                      <Grid
                        container
                        flexDirection="row"
                        sx={{
                          height: "125px",
                          width: "100%",
                          marginTop: 0,
                          marginLeft: 0,
                          padding: "10px",
                        }}
                      >
                        {/*Input*/}

                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              width: "200px",
                              height: "90px",
                              backgroundColor:
                                amountDeposit !== 0 || amountWithdraw !== 0
                                  ? "#009f83"
                                  : "grey",
                              opacity:
                                amountDeposit !== 0 || amountWithdraw !== 0
                                  ? "1"
                                  : "0.25",
                              color: "white",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                            onClick={() => {
                              if (
                                parseInt(amountDeposit) === 0 &&
                                parseInt(amountWithdraw) === 0
                              ) {
                                return;
                              }
                              finishTransaction();
                            }}
                          >
                            {isFocusedAmountDeposit
                              ? t("sales.deposit")
                              : t("sales.withdraw")}
                          </Button>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              width: "200px",
                              height: "90px",
                              backgroundColor: "#fc86c1",
                              color: "white",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                            onClick={() => {
                              updateShowSpillerkonto(false);
                            }}
                          >
                            {t("sales.cancel")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSpillerkonto;
