import React, { useState, useEffect, useContext } from "react";
import { Grid, Skeleton } from "@mui/material/";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";
import {
  Person,
  QrCode as QrCodeIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
} from "@mui/icons-material";

import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";

function LastUsers() {
  const { socket } = useContext(SocketContext);

  const {
    hall,
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsHybridUser,
    updateIsAnonUser,
    updateIsFullUser,
    updateIsFastUserLogin,
    operator,
    updateUserInfo,
    updateUserChildren,
    updateUserSiblings,
    updateAllUsers,
    favUsers,
    updateFavUsers,
  } = useContext(AppContext);

  const [lastUsers, setLastUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleGetLastTickets = (data) => {
      setLastUsers(data);
    };

    const handleLastUsers = (data) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);

      if (!data) {
        return;
      }
      setLastUsers(data);
      updateAllUsers(data);
    };

    const handleSearchMember = (data) => {
      //updateRefreshUser(false);

      if (!data || !data.records) {
        toast.error(t("error.error"));
        return;
      }
      if (data.records.length === 0 || data.records.length > 1) {
        return;
      }
      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]["7 digit PIN"]);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      if (
        data.records[0].username.slice(0, 4) === "anon" ||
        (data.records[0].firstname === "" && data.records[0].mobile === "")
      ) {
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
      } else if (data.records[0].username.slice(0, 6) === "hybrid") {
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(true);

      var dataToRegister = {
        action: "registerLastUsers",
        operator: operator,
        bingoName: hall,
        userInfo: data.records[0],
      };
      registerLastUsers(dataToRegister);
    };

    const handleAddFavourite = (data) => {
      //setLastUsers(data);
      if (!data) {
        updateFavUsers([]);
        return;
      }
      data.sort((a, b) => new Date(b.date) - new Date(a.date));

      updateFavUsers(data);
    };

    const handleRemoveFavourite = (data) => {
      //setLastUsers(data);
      if (!data) {
        updateFavUsers([]);
        return;
      }
      data.sort((a, b) => new Date(b.date) - new Date(a.date));

      updateFavUsers(data);
    };

    const handleGetFavourites = (data) => {
      if (!data) {
        updateFavUsers([]);
        return;
      }
      console.log("favourites", data);
      updateFavUsers(data);
    };

    const handleGetPowerBingoUsers = (data) => {
      //console.log("powerbingo", data);
    };

    socket.on("getLastTickets", handleGetLastTickets);
    socket.on("lastUsers", handleLastUsers);
    socket.on("searchMember", handleSearchMember);

    socket.on("addFavourite", handleAddFavourite);
    socket.on("removeFavourite", handleRemoveFavourite);
    socket.on("getFavourites", handleGetFavourites);
    socket.on("getPowerBingoUsers", handleGetPowerBingoUsers);

    //socket.emit("getPowerBingoUsers", true);

    var data = {
      operator: operator,
      bingoName: hall,
      action: "getFavourites",
      session: session,
    };
    socket.emit("getFavourites", data);
    socket.emit("lastUsers", data);

    return () => {
      socket.off("getLastTickets", handleGetLastTickets);
      socket.off("lastUsers", handleLastUsers);
      socket.off("searchMember", handleSearchMember);

      socket.off("addFavourite", handleAddFavourite);
      socket.off("removeFavourite", handleRemoveFavourite);
      socket.off("getFavourites", handleGetFavourites);
      socket.off("getPowerBingoUsers", handleGetPowerBingoUsers);
    };
  }, [socket]);

  const registerLastUsers = (data) => {
    socket.emit("registerLastUsers", data);
  };

  const { t } = useTranslation();

  const addToFavourite = (user) => {
    console.log("user", user);
    if (isPincodePresent(favUsers, user)) {
      var data = {
        user7Pincode: user.user7Pincode,
        operator: operator,
        bingoName: hall,
        action: "removeFavourite",
        session: session,
        username: user.username,
        lastname: user.lastname,
        phonenumber: user.phonenumber,
        userID: user.userID,
        firstname: user.firstname,
      };
      socket.emit("removeFavourite", data);
      return;
    }

    var data = {
      user7Pincode: user.user7Pincode,
      userInfo: user.userInfo,
      operator: operator,
      bingoName: hall,
      action: "addFavourite",
      session: session,
      username: user.username,
      lastname: user.lastname,
      phonenumber: user.phonenumber,
      userID: user.userID,
      firstname: user.firstname,
    };
    socket.emit("addFavourite", data);
  };

  function isPincodePresent(array, objectToCheck) {
    for (const obj of array) {
      if (obj.user7Pincode === objectToCheck.user7Pincode) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {/* TRANS CONTAINER */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "25px",
          marginBottom: "15px",
          height: "calc(100% - 95px)",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          backgroundColor: "#15192b",
          borderRadius: "15px",
          padding: "15px",
        }}
      >
        {/*Transactions*/}
        <Grid
          container
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignContent: "flex-start",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "75px",
              width: "100%",
              padding: "0px 20px",
            }}
          >
            <h2> {t("sales.lastUsers")}</h2>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "calc(100% - 100px)",
              width: "100%",
              padding: "0px 22px",
              marginTop: "0px",
              overflow: "auto",
            }}
            className="hide-scrollbar"
          >
            {isLoading && (
              <>
                {Array.from({ length: 10 }).map((_, index) => (
                  <div
                    key={index}
                    style={{
                      borderBottom: "1px solid white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={180}
                        height={20}
                        style={{ marginBottom: "15px", marginTop: "15px" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={180}
                        height={20}
                        style={{ marginBottom: "15px" }}
                      />
                    </div>
                    <Skeleton variant="rectangular" width={40} height={40} />
                  </div>
                ))}
              </>
            )}

            {favUsers.length > 0 && !isLoading && (
              <>
                {favUsers.map((user, index) => {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          //SEARCH USER
                          var data = {
                            auth_value: user.user7Pincode,
                            searchByPin: true,
                            action: "searchMember",
                            session: session,
                          };
                          socket.emit("searchMember", data);
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "19px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Person
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />{" "}
                          {user.firstname ? user.firstname : "Uregistrert"}{" "}
                          {user.lastname ? user.lastname : ""}
                        </h2>

                        <p
                          style={{
                            fontSize: "17px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",

                            margin: "0px 0px 10px",
                            padding: "0px 0px",
                          }}
                        >
                          <QrCodeIcon
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />{" "}
                          {user.user7Pincode}
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "17px",
                          lineHeight: "normal",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          addToFavourite(user);
                        }}
                      >
                        <StarIcon
                          sx={{ fontSize: "22px", marginRight: "8px" }}
                        />
                      </p>
                    </div>
                  );
                })}
              </>
            )}

            {lastUsers && lastUsers.length > 0 && !isLoading && (
              <>
                {lastUsers.map((user, index) => {
                  if (isPincodePresent(favUsers, user)) {
                    return "";
                  }
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          //SEARCH USER

                          var data = {
                            auth_value: user.user7Pincode,
                            searchByPin: true,
                            action: "searchMember",
                            session: session,
                          };
                          socket.emit("searchMember", data);
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "19px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Person
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />{" "}
                          {user.firstname} {user.lastname ? user.lastname : ""}
                        </h2>

                        <p
                          style={{
                            fontSize: "17px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",

                            margin: "0px 0px 10px",
                            padding: "0px 0px",
                          }}
                        >
                          <QrCodeIcon
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />{" "}
                          {user.user7Pincode}
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "17px",
                          lineHeight: "normal",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          addToFavourite(user);
                        }}
                      >
                        <StarOutlineIcon
                          sx={{ fontSize: "22px", marginRight: "8px" }}
                        />
                      </p>
                    </div>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default LastUsers;
