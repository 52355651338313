import React, { useEffect, useState, useContext, useRef } from 'react';
import { Grid, Box, TextField, Typography, Button } from '@mui/material';
import { AppContext } from '../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../assets/css/buttons.css';
import Calculator from '../../components/Calculator/Calculator';
import { SocketContext } from '../../context/SocketContext';
import SpecialGames from '../../assets/img/specialGames.png';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/css/buttons.css';
import PaidIcon from '@mui/icons-material/Paid';
import toast, { Toaster } from 'react-hot-toast';

function ModalSpecialGames() {
  const { t } = useTranslation();

  const { updateShowSpecialGames, updateSpecialGameInfo, specialGameInfo } =
    useContext(AppContext);

  const { socketPW } = useContext(SocketContext);

  const [amountGames, setAmountGames] = useState(0);
  const amountGamesRef = useRef(amountGames);

  const [noPrize, setNoPrize] = useState(false);

  useEffect(() => {
    const handlePaymentSpecialGames = (data) => {
      console.log('handlePaymentSpecialGames', data);
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      modalSuccess();
    };

    socketPW.on('paymentSpecialGames', handlePaymentSpecialGames);

    return () => {
      socketPW.off('paymentSpecialGames', handlePaymentSpecialGames);
    };
  }, [socketPW]);

  useEffect(() => {
    amountGamesRef.current = amountGames;
  }, [amountGames]);

  useEffect(() => {
    if (specialGameInfo.isLuckyDay) {
      setAmountGames(specialGameInfo.amount);
    }
  }, [specialGameInfo]);

  const updateAmountGames = (value, isTotal) => {
    if (specialGameInfo.isLuckyDay) {
      return;
    }
    if (
      (amountGames === 0 || amountGames === '0') &&
      (value === '0' || value === '00')
    ) {
      return;
    }

    if ((amountGames === '0' || amountGames === 0) && value !== 'backspace') {
      setAmountGames(value);
      return;
    }
    if ((amountGames === '0' || amountGames === 0) && value === 'backspace') {
      return;
    }
    if (
      value === 'backspace' &&
      amountGames.toString().length === 1 &&
      (amountGames !== '0' || amountGames !== 0)
    ) {
      setAmountGames('0');
      return;
    }
    if (
      value === 'backspace' &&
      amountGames.toString().length > 1 &&
      (amountGames !== '0' || amountGames !== 0)
    ) {
      setAmountGames(
        amountGames.toString().substring(0, amountGames.toString().length - 1)
      );

      return;
    }

    if (isTotal) {
      setAmountGames(value.toString());
      return;
    }

    setAmountGames(amountGames + value);
  };

  useEffect(() => {
    amountGamesRef.current = amountGames;
  }, [amountGames]);

  const modalEnd = () => {
    confirmAlert({
      title: t('header.specialGameMessage') + ' ' + amountGames + ' Kr',
      buttons: [
        {
          label: t('sales.confirm'),
          onClick: () => {
            var data = {
              amount: amountGames,
              gameID: specialGameInfo.gameID,
            };

            socketPW.emit('paymentSpecialGames', data);
            //updateShowSpecialGames(false);
          },
          className: 'btn-custom-yes',
        },

        {
          label: t('sales.cancel'),
          onClick: () => {},
          className: 'btn-custom-no',
        },
      ],
    });
  };

  const modalSuccess = () => {
    confirmAlert({
      title: noPrize
        ? t('header.specialGameMessageConfirmNoPrize')
        : t('header.specialGameMessageConfirm'),
      buttons: [
        {
          label: t('sales.confirm'),
          onClick: () => {
            updateSpecialGameInfo(false);
            updateShowSpecialGames(false);
          },
          className: 'btn-custom-yes',
        },
      ],
    });
  };

  const handleConfirm = () => {
    if (parseInt(amountGames) === 0) {
      return;
    }
    modalEnd();
    //finishTransaction();
  };
  const handleCancel = () => {
    updateShowSpecialGames(false);
  };

  function getDayOfMonth() {
    const today = new Date(); // Get the current date
    return today.getDate(); // Extract and return the day of the month
  }

  function handleYesLuckyDay() {
    setNoPrize(true);
    modalEnd();
  }

  function handleNoLuckyDay() {
    var data = {
      amount: 0,
      gameID: specialGameInfo.gameID,
    };

    socketPW.emit('paymentSpecialGames', data);
  }

  return (
    <>
      {specialGameInfo.isLuckyDay ? (
        <>
          <div id="react-confirm-alert">
            <div className="react-confirm-alert-overlay">
              <div className="react-confirm-alert">
                <div
                  className="react-confirm-alert-body"
                  style={{ width: '1000px' }}
                >
                  <h1>{t('sales.newSpecialGameLuckyDay')} </h1>
                  <p>{t('sales.newSpecialGameLuckyDayText1')} </p>
                  <p>{t('sales.newSpecialGameLuckyDayText2')} </p>
                  <p>
                    {t('sales.newSpecialGameLuckyDayText3') + ' '}
                    <span
                      style={{
                        fontSize: '22px',
                        color: '#42a5f5',
                        fontWeight: 'bold',
                      }}
                    >
                      {getDayOfMonth()}{' '}
                    </span>
                  </p>
                  <p>{t('sales.newSpecialGameLuckyDayText4')} </p>

                  <div
                    className="react-confirm-alert-button-group"
                    style={{ marginTop: '55px' }}
                  >
                    <button
                      className="btn-custom-yes"
                      style={{
                        backgroundColor: '#009e82 !important',
                      }}
                      onClick={() => {
                        handleYesLuckyDay();
                      }}
                    >
                      {t('sales.yes')}
                    </button>
                    <button
                      className="btn-custom-no"
                      onClick={() => {
                        handleNoLuckyDay();
                      }}
                    >
                      {t('sales.no')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="modal-overlay">
          <div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <div
                className="custom-modal-2"
                style={{
                  background: 'white',
                  borderRadius: '40px',
                  width: '1165px',
                  height: '839px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: '10px',
                  }}
                >
                  {/*HEADER*/}
                  <Grid
                    container
                    sx={{
                      height: '100%',
                      width: '100%',
                      marginTop: 0,
                      marginLeft: 0,
                      justifyContent: 'center',
                      alignContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          padding: '10px',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        <Grid
                          container
                          flexDirection="row"
                          sx={{
                            height: '100%',
                            width: '100%',
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: 'center',
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          {/* TITLE */}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: '10px',
                              height: '75px',
                              width: '100%',
                              marginTop: 0,
                              marginLeft: 0,
                              justifyContent: 'flex-start',
                              alignContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <h2
                              style={{
                                color: '#464646',
                              }}
                            >
                              {t('header.specialGame')}
                            </h2>
                          </Grid>

                          {/* CARD LOGO */}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: '10px',
                              height: '250px',
                              width: '100%',
                              marginTop: 0,
                              marginLeft: 0,
                              justifyContent: 'flex-start',
                              alignContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: '250px',
                                width: 'auto',
                                paddingTop: 0,
                              }}
                              alt=""
                              src={SpecialGames}
                            />
                          </Grid>

                          {/* INPUTS */}
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: 'calc(100% - 405px)',
                              width: '100%',
                              alignContent: 'space-around',
                            }}
                          >
                            {/*Input*/}
                            <Grid
                              item
                              xs={12}
                              sx={{
                                padding: '10px',
                                height: '100px',
                                width: '100%',
                                marginTop: 0,
                                marginLeft: 0,
                                justifyContent: 'center',
                                alignContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Grid
                                container
                                flexDirection="row"
                                sx={{
                                  height: '100%',
                                  width: '100%',
                                  marginTop: 0,
                                  marginLeft: 0,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  marginTop: '-75px',
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    height: '75px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <PaidIcon
                                      sx={{
                                        fontSize: '45px',
                                        marginRight: '10px',
                                        color: '#464646',
                                      }}
                                    />
                                    <div>
                                      <Typography
                                        sx={{
                                          fontSize: '19px',
                                          fontWeight: 'bold',
                                          textTransform: 'uppercase',
                                          color: '#464646',
                                        }}
                                      >
                                        {t('sales.amount')}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: '15px',
                                          color: '#464646',
                                        }}
                                      >
                                        {t('header.specialGameSubtitle')}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    marginTop: 0,
                                    padding: '10px',
                                    alignContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <TextField
                                    autoComplete="off"
                                    value={
                                      amountGames === 0 || amountGames === '0'
                                        ? ''
                                        : amountGames
                                    }
                                    sx={{
                                      '& label': {
                                        position: 'relative !important',
                                        fontSize: '22px !important',
                                        color: '#464646 !important',
                                      },
                                      '& label.Mui-focused': {
                                        position: 'relative !important',
                                        color: '#464646 !important',
                                      },
                                      '& input': {
                                        color: '#464646 !important',
                                        border: '3px solid #464646',
                                        borderRadius: '4px',
                                        height: '50px !important',
                                        width: '275px !important',
                                        fontSize: '40px !important',
                                        textAlign: 'center',
                                      },
                                      '& input.Mui-focused': {
                                        color: '#464646 !important',
                                        border: '3px solid #464646',
                                        borderRadius: '4px',
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                          borderColor: 'transparent',
                                        },
                                      },
                                      '& .MuiInputBase-input': {
                                        caretColor: 'transparent',
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sx={{
                          padding: '10px',
                        }}
                      >
                        {/* SUBTITLE SALDO */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: '10px',
                            height: '35px',
                            width: '100%',
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: 'flex-start',
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              color: '#464646',
                              fontWeight: 'bold',
                              fontSize: '18px',
                            }}
                          ></p>
                        </Grid>

                        <Calculator
                          updateAmount={updateAmountGames}
                          isColorCard={true}
                        />
                        <Grid
                          item
                          xs={12}
                          sx={{
                            width: '100%',
                            maxWidth: '100% !important',
                            height: '100%',
                          }}
                        >
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: '125px',
                              width: '100%',
                              marginTop: 0,
                              marginLeft: 0,
                              padding: '10px',
                            }}
                          >
                            {/*Input*/}

                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                variant="contained"
                                component="label"
                                sx={{
                                  width: '200px',
                                  height: '90px',
                                  backgroundColor:
                                    amountGames != 0 ? '#009f83' : 'grey',
                                  opacity: amountGames != 0 ? '1' : '0.25',
                                  color: 'white',
                                  fontWeight: 'bold',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => {
                                  handleConfirm();
                                }}
                              >
                                {t('sales.confirm')}
                              </Button>
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                variant="contained"
                                component="label"
                                sx={{
                                  width: '200px',
                                  height: '90px',
                                  backgroundColor: '#fc86c1',
                                  color: 'white',
                                  fontWeight: 'bold',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => {
                                  handleCancel();
                                }}
                              >
                                {t('sales.cancel')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalSpecialGames;
