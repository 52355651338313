import React, { useState, useContext, useEffect, useRef } from "react";
import { Grid, TextField, Tabs, Tab, Box, Typography } from "@mui/material";
import { Person, AttachMoney as AttachMoneyIcon } from "@mui/icons-material";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import SellScreenList from "./SellScreenList";
import Shortcuts from "../../../components/Shortcuts/Shortcuts";
import SellScreenListEBS from "./SellScreenListEBS";
import SellScreenListOKD from "./SellScreenListOKD";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SellScreenDescription({
  amount,
  description,
  action,
  isShortcut,
  isPayoutTicket,
  updateAmountShortcut,
  isUserSaldo,
  isRefill,
}) {
  const { t } = useTranslation();
  const [isFocusedAmount, setIsFocusedAmount] = useState(false);
  const [isFocusedTicket, setIsFocusedTicket] = useState(false);

  const { userInfo, currentTicketEBS, currentTicketOK, isOKBingoEnabled } =
    useContext(AppContext);

  const isOKBingoEnabledRef = useRef(isOKBingoEnabled);
  useEffect(() => {
    isOKBingoEnabledRef.current = isOKBingoEnabled;
  }, [isOKBingoEnabled]);

  const amountFocus = () => {
    setIsFocusedAmount(true);
    isFocusedTicket(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("description", description);
  }, [description]);

  useEffect(() => {
    console.log(currentTicketEBS);
    if (currentTicketEBS && action === "Utbetaling") {
      updateAmountShortcut(currentTicketEBS.currentBalance);
    }
  }, [currentTicketEBS]);

  useEffect(() => {
    console.log(currentTicketOK);
    if (currentTicketOK && action === "Utbetaling") {
      updateAmountShortcut(currentTicketOK.amount);
    }
  }, [currentTicketOK]);

  return (
    <Grid
      container
      flexDirection="row"
      sx={{
        height: "100%",
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {/* Action-description title */}
      <Grid
        item
        xs={12}
        sx={{
          padding: "10px",
          height: "75px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          justifyContent: "flex-start",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h2>
          {action} - {description}
        </h2>
      </Grid>

      {/* Body description */}
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "calc(100% - 90px)",
          width: "100%",
          alignContent: "space-evenly",
        }}
      >
        {/*Input*/}
        <Grid
          item
          xs={12}
          sx={{
            padding: "10px",
            /*height: !isTicketCH ? "calc(100% - 75px)" : "100px",*/
            height: "100px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            flexDirection="row"
            sx={{
              height: "100%",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {isUserSaldo && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: "10px",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "75px",
                  fontSize: "19px",
                  fontWeight: "bold",
                  flexDirection: "row",
                }}
              >
                <Grid
                  container
                  flexDirection="row"
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    marginLeft: "-35px",
                  }}
                >
                  <Grid
                    item
                    xs={3}
                    sx={{
                      padding: "10px",
                      alignContent: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "75px",
                      fontSize: "19px",
                      fontWeight: "bold",
                      flexDirection: "row",
                    }}
                  >
                    <Person
                      sx={{ fontSize: "35px", margin: "-5px 0 0 10px" }}
                    />{" "}
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{
                      padding: "10px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: "75px",
                      fontSize: "19px",
                      fontWeight: "bold",
                      flexDirection: "row",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginLeft: "-30px",
                        textTransform: "uppercase",
                      }}
                    >
                      Saldo: {userInfo.balances.wallet}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                height: "75px",
                fontSize: "19px",
                fontWeight: "bold",
                flexDirection: "row",
              }}
            >
              <Grid
                container
                flexDirection="row"
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginLeft: "-35px",
                }}
              >
                <Grid
                  item
                  xs={3}
                  sx={{
                    padding: "10px",
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "75px",
                    fontSize: "19px",
                    fontWeight: "bold",
                    flexDirection: "row",
                  }}
                >
                  <AttachMoneyIcon
                    sx={{ fontSize: "35px", margin: "-5px 0 0 10px" }}
                  />{" "}
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{
                    padding: "10px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: "75px",
                    fontSize: "19px",
                    fontWeight: "bold",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "-30px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("sales.amount")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 0,
                padding: "10px",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                onFocus={() => amountFocus()}
                onBlur={() => setIsFocusedAmount(false)}
                value={amount}
                sx={{
                  "& label": {
                    position: "relative !important",
                    fontSize: "22px !important",
                    color: "white !important",
                  },
                  "& label.Mui-focused": {
                    position: "relative !important",
                    color: "white !important",
                  },
                  "& input": {
                    color: "white !important",
                    border: "3px solid white",
                    borderRadius: "4px",
                    height: "50px !important",
                    width: "275px !important",
                    fontSize: "40px !important",
                    textAlign: "center",
                  },
                  "& input.Mui-focused": {
                    color: "white !important",
                    border: "3px solid white",
                    borderRadius: "4px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*Shortcuts*/}
        {isShortcut && !isPayoutTicket && (
          <>
            <Shortcuts updateAmountShortcut={updateAmountShortcut} />
          </>
        )}

        {/*List */}
        {isPayoutTicket && !isShortcut && (
          <>
            {description !== "EBS" && description !== "OK Databingo" && (
              <SellScreenList
                updateAmountShortcut={updateAmountShortcut}
                isRefill={isRefill}
              />
            )}
            {description === "OK Databingo" && isOKBingoEnabledRef.current && (
              <SellScreenListOKD
                updateAmountShortcut={updateAmountShortcut}
                isRefill={isRefill}
              />
            )}
            {description === "EBS" && (
              <SellScreenListEBS
                updateAmountShortcut={updateAmountShortcut}
                isRefill={isRefill}
              />
            )}
          </>
        )}

        {/*Shortcuts and list (Refill)*/}
        {isShortcut && isPayoutTicket && (
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t("sales.payoutTicket")}
                sx={{
                  "&.Mui-selected": {
                    display: "block",
                    fontSize: "1em",
                    marginBlockStart: "0.83em",
                    marginBlockEnd: "0.83em",
                    marginInlineStart: "0px",
                    marginInlinEend: "0px",
                    fontWeight: "bold",
                    color: "white",
                  }, // style for active state
                  "&:hover": { color: "blue", backgroundColor: "lightgray" }, // style for hover state
                  color: "gray", // default style
                }}
              />
              <Tab
                label={t("sales.shortcuts")}
                sx={{
                  "&.Mui-selected": {
                    display: "block",
                    fontSize: "1em",
                    marginBlockStart: "0.83em",
                    marginBlockEnd: "0.83em",
                    marginInlineStart: "0px",
                    marginInlinEend: "0px",
                    fontWeight: "bold",
                    color: "white",
                  }, // style for active state
                  "&:hover": { color: "blue", backgroundColor: "lightgray" }, // style for hover state
                  color: "gray", // default style
                }}
              />
            </Tabs>
            <TabPanel
              value={value}
              index={0}
              sx={{ padding: "0px !important" }}
            >
              {description !== "EBS" && description !== "OK Databingo" && (
                <SellScreenList
                  updateAmountShortcut={updateAmountShortcut}
                  isTab={true}
                  isRefill={isRefill}
                />
              )}
              {description === "OK Databingo" &&
                isOKBingoEnabledRef.current && (
                  <SellScreenListOKD
                    updateAmountShortcut={updateAmountShortcut}
                    isTab={true}
                    isRefill={isRefill}
                  />
                )}
              {description === "EBS" && (
                <SellScreenListEBS
                  updateAmountShortcut={updateAmountShortcut}
                  isTab={true}
                  isRefill={isRefill}
                />
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              sx={{ padding: "0px !important" }}
            >
              <Shortcuts
                updateAmountShortcut={updateAmountShortcut}
                isTab={true}
              />
            </TabPanel>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default SellScreenDescription;
