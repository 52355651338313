import React, { useEffect, useContext, useState } from 'react';
import { Grid, Button } from '@mui/material/';
import { Link } from 'react-router-dom';
import useRoutes from '../../routes/Routes';
import { confirmAlert } from 'react-confirm-alert';
import { AppContext } from '../../context/Context';
import { SocketContext } from '../../context/SocketContext';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/css/buttons.css';
import { useTranslation } from 'react-i18next';
import toast, { Toaster } from 'react-hot-toast';

function MenuButtons() {
  const Routes = useRoutes();
  const { t } = useTranslation();
  const { socket } = useContext(SocketContext);
  const { socketPW } = useContext(SocketContext);

  const [isVisible, setIsVisible] = useState(false);

  const handleToggleVisibility = () => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleToggleVisibility();
    }, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const {
    hall,
    updateShowSpecialGames,
    transactions,
    updateShowModalEndShift,
    currentUser,
    updateShowModalEditUserLimits,
    enviroment,
    userLimits,
    updateSpecialGameInfo,
  } = useContext(AppContext);

  useEffect(() => {}, [socket]);

  useEffect(() => {
    const handleCheckIsSpecialGameOpen = (data) => {
      console.log('special games', data);
      if (!data || (!data.isLykkehjul && !data.isLuckyDay)) {
        modalNoSpecialGames();
        //updateSpecialGameInfo(false);
        return;
      }
      if (data.isLykkehjul === true || data.isLuckyDay === true) {
        updateShowSpecialGames(true);
        updateSpecialGameInfo(data);
      }
    };

    const handleNewSpecialGame = (data) => {
      console.log('newSpecialGame', data);
      if (data.bingoName && data.bingoName === hall) {
        //if (data.bingoName && data.bingoName === 'Bingo 1 Demo PROD') {
        toast.success(t('header.specialGameAvailable'), {
          duration: 7000,
        });
      }
    };

    socketPW.on('checkIsSpecialGameOpen', handleCheckIsSpecialGameOpen);
    socketPW.on('newSpecialGame', handleNewSpecialGame);

    return () => {
      socketPW.off('checkIsSpecialGameOpen', handleCheckIsSpecialGameOpen);
      socketPW.off('newSpecialGame', handleNewSpecialGame);
    };
  }, [socketPW]);

  const modalNoSpecialGames = () => {
    confirmAlert({
      title: t('header.specialGameMessageError'),
      buttons: [
        {
          label: t('sales.confirm'),
          onClick: () => {},
          className: 'btn-custom-yes',
        },
      ],
    });
  };

  const checkSpecialGames = () => {
    if (transactions.length > 0) {
      toast.error(t('error.errorNavigateTransactions'), {
        duration: 5000,
      });
      return;
    }
    socketPW.emit('checkIsSpecialGameOpen', 'checkIsSpecialGameOpen');
  };

  const checkTrans = (event) => {
    if (transactions.length > 0) {
      event.preventDefault();
      toast.error(t('error.errorNavigateTransactions'), {
        duration: 5000,
      });
      return;
    }
    if (currentUser !== '') {
      event.preventDefault();
      toast.error(t('error.errorNavigateTransactions'), {
        duration: 5000,
      });
      return;
    }
  };

  const modalEndShift = () => {
    if (transactions.length > 0) {
      toast.error(t('error.errorNavigateTransactions'), {
        duration: 5000,
      });
      return;
    }
    if (currentUser !== '') {
      toast.error(t('error.errorNavigateTransactions'), {
        duration: 5000,
      });
      return;
    }
    updateShowModalEndShift(true);
  };

  const handleUserLimits = () => {
    updateShowModalEditUserLimits(true);
  };

  return (
    <Grid
      container
      direction="row"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        borderBottom: '6px solid black',
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: '9999999 !important',
            fontSize: '24px',
          },
        }}
        containerStyle={{
          zIndex: 99999999, // Ensure this value is higher than the modal's z-index
        }}
      />
      {Routes.map((route, index) => {
        if (
          Routes[index].name === 'login' ||
          Routes[index].name === t('header.endShift')
        ) {
          return;
        }
        return (
          <Grid
            item
            xs={6}
            sx={{
              paddingTop: '0px !important',
              paddingLeft: '0px !important!',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItem: 'center',
              margin: '15px 0 35px 0',
            }}
            key={index}
          >
            {Routes[index].name === t('header.sales') ||
            Routes[index].name === t('header.lastTransactions') ||
            Routes[index].name === t('header.lastCardTransactions') ||
            Routes[index].name === t('header.ticketHistoric') ||
            Routes[index].name === t('header.openTickets') ||
            Routes[index].name === t('header.recent') ? (
              <Link
                to={Routes[index].route}
                onClick={(event) => {
                  checkTrans(event);
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: 85,
                    width: 95,
                    padding: '10px !important',
                    backgroundColor: '#889cab',
                    '&:hover': {
                      backgroundColor: '#e566a7',
                    },
                    fontWeight: 'bold',
                    color: 'white',
                    fontSize: '12px',
                  }}
                >
                  {Routes[index].name}
                </Button>
              </Link>
            ) : (
              <Button
                variant="contained"
                sx={{
                  height: 85,
                  width: 95,
                  padding: '10px !important',
                  backgroundColor: 'grey',
                  '&:hover': {
                    backgroundColor: '#e566a7',
                  },
                  fontWeight: 'bold',
                  color: 'white',
                  fontSize: '12px',
                  opacity: '0.25',
                }}
              >
                {Routes[index].name}
              </Button>
            )}
          </Grid>
        );
      })}

      <Grid
        item
        xs={6}
        sx={{
          paddingTop: '0px !important',
          paddingLeft: '0px !important!',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItem: 'center',
          margin: '15px 0 35px 0',
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: 85,
            width: 95,
            padding: '10px !important',
            backgroundColor: '#889cab',
            '&:hover': {
              backgroundColor: '#e566a7',
            },
            fontWeight: 'bold',
            color: 'white',
            fontSize: '12px',
          }}
          onClick={() => {
            modalEndShift();
          }}
        >
          {t('header.endShift')}
        </Button>
      </Grid>

      <Grid
        item
        xs={6}
        sx={{
          paddingTop: '0px !important',
          paddingLeft: '0px !important!',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItem: 'center',
          margin: '15px 0 35px 0',
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: 85,
            width: 95,
            padding: '10px !important',
            backgroundColor: '#889cab',
            '&:hover': {
              backgroundColor: '#e566a7',
            },
            fontWeight: 'bold',
            color: 'white',
            fontSize: '12px',
          }}
          onClick={() => {
            checkSpecialGames();
          }}
        >
          {t('header.specialGame')}
        </Button>
      </Grid>

      {enviroment !== 'prod' && (
        <Grid
          item
          xs={6}
          sx={{
            paddingTop: '0px !important',
            paddingLeft: '0px !important!',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItem: 'center',
            margin: '15px 0 35px 0',
          }}
        >
          {/*
          <Button
            variant="contained"
            sx={{
              height: 85,
              width: 95,
              padding: "10px !important",
              backgroundColor: "#889cab",
              "&:hover": {
                backgroundColor: "#e566a7",
              },
              fontWeight: "bold",
              color: "white",
              fontSize: "11px",
              opacity: currentUser === "" ? "0.25" : "1",
            }}
            onClick={() => {
              if (currentUser === "") {
                return;
              }
              handleUserLimits();
            }}
          >
            {t("header.userLimits")}
          </Button>
          */}
        </Grid>
      )}

      {userLimits.limitDaily ? (
        <Grid
          item
          xs={10}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItem: 'center',
            margin: '0 0 35px 0',
            backgroundColor: 'white',
            height: '140px',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
            padding: '10px 25px',
            flexDirection: 'column',
            textAlign: 'center',
            // Initial state: off-screen when not visible
            //transform: isVisible ? "translateX(0)" : "translateX(-100%)",
            //transition: "transform 0.5s ease-in-out", // Smooth transition
          }}
        >
          <p
            style={{
              color:
                userLimits.limitDaily > userLimits.cashIn - userLimits.cashOut
                  ? 'black'
                  : '#fc86c1',
              fontSize: '14px',
              marginBottom: '0px',
            }}
          >
            {t('sales.limitDaily')}:<br />
            <span style={{ fontSize: '21px' }}>
              {userLimits.cashIn - userLimits.cashOut}
            </span>{' '}
            /{' '}
            <span style={{ fontSize: '21px' }}>
              {userLimits.limitDaily > 0 && userLimits.limitDaily}
            </span>
          </p>
          <p
            style={{
              color:
                userLimits.limitMonthly >
                userLimits.cashInMonth - userLimits.cashOutMonth
                  ? 'black'
                  : '#fc86c1',
              fontSize: '14px',
            }}
          >
            {t('sales.limitMonthly')}:<br />{' '}
            <span
              style={{
                fontSize: '21px',
              }}
            >
              {userLimits.cashInMonth - userLimits.cashOutMonth}
            </span>{' '}
            /{' '}
            <span
              style={{
                fontSize: '21px',
              }}
            >
              {userLimits.limitMonthly > 0 && userLimits.limitMonthly}
            </span>
          </p>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default MenuButtons;
