import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import BlurTable from "../../../assets/img/blurTable.jpg";

import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";

function SellScreenList({ updateAmountShortcut, isTab, isRefill }) {
  const { t } = useTranslation();

  const {
    updateOpenSessionTerminal,
    userLogin,
    userInfo,
    userSiblings,
    userChildren,
    session,
    operator,
    hall,
    updateUserLogin,
    currentUser,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserInfo,
    updateIsFullUser,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    ticketsCH,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    console.log("Plain list");
    //socket.emit("openSessions", userInfo.id);
  }, [userInfo]);

  useEffect(() => {
    const handleSearchMember = (data) => {
      if (!data || !data.records) {
        toast.error(t("error.error"));
        return;
      }
      if (data.records.length > 1) {
        //modalUserFound(data.records);
        return;
      }
      if (data.records.length === 0) {
        //modalUserNotFound();
        return;
      }

      const amountToRefill = data.records[0].balances.wallet;

      //setTicketActive(data.records[0]["7 digit PIN"])

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]["7 digit PIN"]);
      updateCurrentUserID(data.records[0]["id"]);

      socket.emit("openSessions", data.records[0]["id"]);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === "anon" ||
        (data.records[0].firstname === "" && data.records[0].mobile === "")
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            searchByID: true,
            action: "searchMemberParent",
            session: session,
          };
          socket.emit("searchMemberParent", data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === "hybrid") {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: "registerLastUsers",
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: dataToRegisterObj[0]["7 digit PIN"],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ""
            ? "Uregistrert"
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);

      if (!isRefill && isRefill !== undefined) {
        setTimeout(() => {
          updateAmountShortcut(amountToRefill);
        }, 90);
      }
    };

    const handleSms = (data) => {
      setSmsGenerated(data);

      if (!data) {
        toast.error(t("error.error"));
        return;
      }

      setSmsGenerated(data);
    };

    const handleSearchMemberParent = (data) => {};

    // Adding event listeners
    socket.on("searchMember", handleSearchMember);
    socket.on("searchMemberParent", handleSearchMemberParent);
    socket.on("sms", handleSms);

    // Cleanup function
    return () => {
      socket.off("searchMember", handleSearchMember);
      socket.off("sms", handleSms);
      socket.off("searchMemberParent", handleSearchMemberParent);
    };
  }, [socket]);

  const [smsGenerated, setSmsGenerated] = useState(""); // State to control focus
  const [usernameRegister, setUsernameRegister] = useState("");
  const [phonenumberRegister, setPhonenumberRegister] = useState("");

  const smsGeneratedRef = useRef(smsGenerated);
  useEffect(() => {
    smsGeneratedRef.current = smsGenerated;
  }, [smsGenerated]);

  const usernameRegisterRef = useRef(usernameRegister);
  useEffect(() => {
    usernameRegisterRef.current = usernameRegister;
  }, [usernameRegister]);

  const phonenumberRegisterRef = useRef(phonenumberRegister);
  useEffect(() => {
    phonenumberRegisterRef.current = phonenumberRegister;
  }, [phonenumberRegister]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const [dataTickets, setDataTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);

  const registerLastUsers = (data) => {
    if (data.operator === "") {
      return;
    }
    socket.emit("registerLastUsers", data);
  };

  useEffect(() => {
    if (userSiblings.length > 0) {
      setDataTickets(userSiblings);
    } else if (userChildren.length > 0) {
      setDataTickets(userChildren);
    }
  }, [userSiblings, userChildren]);

  useEffect(() => {
    const filterTickets = async () => {
      const result = [];

      for (const ticket of dataTickets) {
        const ticketExist = userExists(ticketsCH, ticket.ticket7Pincode);

        if (!ticketExist) {
          result.push(ticket);
        }
      }

      setFilteredTickets(result);
    };

    filterTickets();
  }, [dataTickets, ticketsCH]);

  const userExists = (array, user7Pincode) => {
    return array.some((user) => user.user7Pincode === user7Pincode);
  };

  const activateTicket = (ticket) => {
    var data = {
      auth_value: ticket.ticket7Pincode,
      searchByPin: true,
      action: "searchMember",
      session: session,
    };
    socket.emit("searchMember", data);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "10px",
        height: "555px",
        width: "100%",
        marginTop: "40px",
        marginLeft: 0,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {!isTab && (
        <h2 style={{ textAlign: "left", width: "100%" }}>
          {t("sales.payoutTicket")}
        </h2>
      )}

      <Grid
        container
        flexDirection="row"
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: "2px solid black",
            width: "100%",
            maxWidth: "100% !important",
            height: "535px",
            backgroundColor: "#232a45",
          }}
        >
          {false ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
                alignItems: "center",
                backgroundImage: `url(${BlurTable})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                {t("sales.unblock")}
              </p>
              <Button
                variant="contained"
                component="label"
                sx={{
                  height: "90px",
                  width: "150px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "#42a5f5",
                  color: "white",
                  marginTop: "0px",
                }}
                onClick={() => {
                  //updateAmountShortcut(10);
                }}
              >
                {t("sales.send")}
              </Button>
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                /*maxHeight: "calc(100% - 200px)",
                marginBottom: "200px",*/
                height: "535px",
                overflowY: "scroll", // Enables vertical scrolling
                scrollbarWidth: "none", // For Firefox
                "&::-webkit-scrollbar": {
                  display: "none", // For Chrome, Safari, and Opera
                },
              }}
            >
              <Table
                sx={{
                  color: "white",
                  background: "#1f2c67",
                }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    background: "#1f2c67",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {t("apneBilleter.ticketID")}
                    </TableCell>
                    <TableCell align="center">{t("login.username")}</TableCell>

                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTickets.length > 0 && (
                    <>
                      {filteredTickets.map((ticket, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:nth-of-type(odd)": {
                              backgroundColor: "#232a45",
                            },
                            "&:nth-of-type(even)": {
                              backgroundColor: "#1a2036",
                            },
                          }}
                          key={index}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            sx={{
                              color:
                                currentUser === ticket.ticket7Pincode
                                  ? "#18ff18"
                                  : "inherit",
                            }}
                          >
                            {ticket.ticket7Pincode}
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              color:
                                currentUser === ticket.ticket7Pincode
                                  ? "#18ff18"
                                  : "inherit",
                            }}
                          >
                            {ticket.ticketUsername}
                          </TableCell>

                          <TableCell align="center">
                            {currentUser !== ticket.ticket7Pincode ? (
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  activateTicket(ticket);
                                }}
                              >
                                {!isRefill
                                  ? t("sales.payout")
                                  : t("sales.refill")}
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                  activateTicket(ticket);
                                }}
                              >
                                {t("sales.selected")}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellScreenList;
