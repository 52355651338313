import React, { useContext, useState, useRef, useEffect } from 'react';
import {
  Grid,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@mui/material';

import {
  Search as SearchIcon,
  Person as PersonIcon,
  PersonAdd as PersonAddIcon,
  PhoneAndroid,
  FiberPin,
  GroupAdd,
  PersonSearch as PersonSearchIcon,
  AutoAwesome as AutoAwesomeIcon,
  ContactMail as ContactMailIcon,
  ContactEmergency as ContactEmergencyIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';

import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/Context';

import { SocketContext } from '../../../context/SocketContext';
import ModalUserFound from './ModalUserFound';
import ModalUserNotFound from './ModalUserNotFound';
import ModalOpenSession from '../UserModals/ModalOpenSession';

function HeaderSearcher() {
  const { t } = useTranslation();

  const { socket } = useContext(SocketContext);

  const {
    session,
    hall,
    operator,
    autoSearch,
    searchByPin,
    searchByUsername,
    searchByPhonenumber,
    searchByFirstname,
    searchByLastname,
    updateAutoSearch,
    updateSearchByPin,
    updateSearchByPhonenumber,
    updateSearchByUsername,
    updateSearchByFirstname,
    updateSearchByLastname,
    updateIsHybridUserRegistration,
    updateIsFullUserRegistration,
    updateRefreshUser,
    updateUserLogin,
    updateUserInfo,
    updateCurrentUserID,
    updateCurrentUser,
    updateUserSiblings,
    updateUserChildren,
    updateIsFullUser,
    updateIsHybridUser,
    updateIsAnonUser,
    updateIsFastUserLogin,
    updateUserParent,
    updateShowModalUserFound,
    showModalUserFound,
    updateShowModalUserNotFound,
    showModalUserNotFound,
    userLogin,
    focusOnMainScreen,
    updateFocusOnMainScreen,
    userInfo,
    updateShowUserInfo,
    updateIsCashoutPending,
    isFullUser,
    refreshUser,
    updateOpenSessionTerminal,
    userToSearch,
    updateUserToSearch,
    usersResults,
    updateUserResults,
  } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null); // Ref for the TextField
  const [isFocused, setIsFocused] = useState(true); // State to control focus
  const focusOnMainScreenRef = useRef(focusOnMainScreen);
  useEffect(() => {
    focusOnMainScreenRef.current = focusOnMainScreen;
  }, [focusOnMainScreen]);

  useEffect(() => {
    if (!userLogin) {
      inputRef.current.focus();
      document.addEventListener('mousedown', handleOutsideClick);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }

    if (!userInfo.balances) {
      return;
    }
    if (userInfo.username.startsWith('anon')) {
      updateShowUserInfo(true);
    }
    //socket.emit("openSessions", userInfo.id);
    //TEST:
    //updateIsCashoutPending(true);

    if (userInfo.balances.cashout > 0 && isFullUser) {
      updateIsCashoutPending(true);
    }
  }, [userLogin]);

  // * Check if a user refresh is needed (after cashin or cashout)
  useEffect(() => {
    if (!refreshUser) {
      return;
    }
    var data = {
      auth_value: userInfo.id,
      action: 'searchMember',
      session: session,
    };
    socket.emit('searchMember', data);
  }, [refreshUser]);

  // * Effect to handle focusing the input initially and adding the event listener
  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isFocused]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //* Outside click, return focus to search if needed
  const handleOutsideClick = (event) => {
    if (!focusOnMainScreen) {
      return;
    }
    if (!focusOnMainScreenRef.current) {
      return;
    }
    if (
      isFocused &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 400);
    }
  };

  //* Clean button
  const handleResetSearch = () => {
    updateUserToSearch('');
    if (inputRef.current) {
      inputRef.current.value = ''; // Clear the input field (not necessary if state is used correctly)
    }
    inputRef.current.focus();
  };

  // * ENTER KEY DETECTED, SEARCH
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchUser();
    }
  };

  const searchUser = async () => {
    var inputString = userToSearch;
    let accumulatedString = '';

    //Check if the input is a URL and search for the ID, barcode or okd value
    async function getIdFromLink(str) {
      if (!str.startsWith('https')) {
        return false;
      }

      // Define an object to store the results
      const result = {};

      // Updated regex to find digits after 'pid', 'mob', 'id', 'okd' with either '¡' or '=' as delimiter
      const urlPattern = /(pid|mob|id|okd)[¡=](\d+)/g;

      // Regex to match strings like 'k1' or 'M' followed by exactly 7 digits
      const barcodePattern = /^(k1|M)(\d{7})$/i;

      // Check if the string starts with 'k1' or 'M' followed by 7 digits
      if (barcodePattern.test(inputString)) {
        const match = inputString.match(barcodePattern);
        result.barcode = match[0]; // Store the matched string
      }

      // Find all occurrences of pid, mob, id, okd and their corresponding digits
      let match;
      while ((match = urlPattern.exec(inputString)) !== null) {
        const key = match[1]; // pid, mob, id, or okd
        const value = match[2]; // the digits after the key
        result[key] = value; // Store in the result object
      }

      return result;
    }

    let decodedUrl = await getIdFromLink(inputString);
    const processExtractedData = (data) => {
      // Process the extracted data
      console.log('Processing extracted data:', data);
      if (data.okd) {
        console.log('Handling OKD:', data.okd);
        var data = {
          okID: decodedUrl.okd,
          action: 'searchTicketOKD',
          session: session,
          searchByID: true,
        };
        socket.emit('searchTicketOKD', data);
      }

      if (data.pid) {
        console.log('Handling PID:', data.pid);
      }

      if (data.id) {
        console.log('Handling ID:', data.id);
        const dataToRequest = {
          auth_value: data.id,
          action: 'searchMember',
          session: session,
          searchByID: true,
        };
        socket.emit('searchMember', dataToRequest);
      }

      if (data.barcode) {
        console.log('Handling barcode pattern:', data.barcode);
        const dataToRequest = {
          auth_value: data.barcode.substring(2),
          action: 'searchMemberHidden',
          session: session,
        };
        console.log('searchMemberHidden', dataToRequest);
        socket.emit('searchMemberHidden', dataToRequest);
      }
    };

    if (decodedUrl) {
      processExtractedData(decodedUrl);
      accumulatedString = '';
      handleResetSearch();
      return;
    }

    // Not a URL, process the input string for intelligent search
    const usernamePattern = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    const pincodePattern = /^[0-9]{7}$/;
    const norwegianPhonePattern = /^[49][0-9]{7}$/;
    const ticketScanPattern = /^k1\d{7}$/i;
    const cardPattern = /^m\d{7}$/i;

    var autoSearchField = false;

    if (pincodePattern.test(inputString) && autoSearch) {
      autoSearchField = '7 digit PIN';
    } else if (norwegianPhonePattern.test(inputString) && autoSearch) {
      autoSearchField = 'mobile';
    } else if (ticketScanPattern.test(inputString) && autoSearch) {
      autoSearchField = '7 digit PIN';
      inputString = inputString.substring(2);
      console.log('substring', inputString);
    } else if (cardPattern.test(inputString) && autoSearch) {
      autoSearchField = 'Membership card ID';
    } else if (usernamePattern.test(inputString) && autoSearch) {
      autoSearchField = 'firstname';
    }

    var data = {
      auth_value: inputString,
      autoSearch: autoSearchField,
      searchByPin: searchByPin,
      searchByUsername: searchByUsername,
      searchByPhonenumber: searchByPhonenumber,
      searchByFirstname: searchByFirstname,
      searchByLastname: searchByLastname,
      action: 'searchMember',
      session: session,
    };

    socket.emit('searchMember', data);
    handleResetSearch();
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: '20px',
        height: '125px',
        width: '100%',
        marginLeft: 0,
        marginBottom: 0,
        backgroundColor: '#15192b',
        padding: 0,
        borderBottom: '5px solid black',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {showModalUserFound && <ModalUserFound users={usersResults} />}
      {showModalUserNotFound && <ModalUserNotFound />}

      {/*SEARCH/USER INPUT CONTAINER*/}
      <Grid
        container
        alignItems="center"
        sx={{
          height: '100%',
          width: '100%',

          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '30px',
        }}
      >
        <Grid
          item
          xs={3}
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={handleClick}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {searchByUsername ? (
              <PersonSearchIcon
                sx={{
                  fontSize: '45px',
                  marginTop: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : searchByPhonenumber ? (
              <PhoneAndroid
                sx={{
                  fontSize: '45px',
                  marginTop: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : searchByPin ? (
              <FiberPin
                sx={{
                  fontSize: '45px',
                  marginTop: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : autoSearch ? (
              <AutoAwesomeIcon
                sx={{
                  fontSize: '45px',
                  marginTop: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : searchByFirstname ? (
              <ContactMailIcon
                sx={{
                  fontSize: '45px',
                  marginTop: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : searchByLastname ? (
              <ContactEmergencyIcon
                sx={{
                  fontSize: '45px',
                  marginTop: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : null}
          </IconButton>

          {/* MENU */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              backgroundColor: 'rgba(0,0,0,0.7)',
              // Hover styles

              '& .MuiPaper-root': {
                backgroundColor: '#15192b',
                width: '250px',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                updateFocusOnMainScreen(false);

                updateIsHybridUserRegistration(true);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <PersonAddIcon
                sx={{
                  fontSize: '50px',
                  marginRight: '5px',
                  transform: 'scaleX(-1)',
                }}
              />
              <p style={{ whiteSpace: 'normal' }}>
                {t('sales.registerNewHybridUser')}
              </p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                //modalNewMemberUser();
                updateFocusOnMainScreen(false);

                updateIsFullUserRegistration(true);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <GroupAdd sx={{ fontSize: '50px', marginRight: '5px' }} />
              <p style={{ whiteSpace: 'normal' }}>{t('header.newMember')}</p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateAutoSearch(true);
                updateSearchByPin(false);
                updateSearchByPhonenumber(false);
                updateSearchByUsername(false);
                updateSearchByFirstname(false);
                updateSearchByLastname(false);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <AutoAwesomeIcon sx={{ fontSize: '50px', marginRight: '5px' }} />

              <p style={{ whiteSpace: 'normal' }}>{t('sales.autoSearch')}</p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateAutoSearch(false);
                updateSearchByPin(true);
                updateSearchByPhonenumber(false);
                updateSearchByUsername(false);
                updateSearchByFirstname(false);
                updateSearchByLastname(false);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <FiberPin sx={{ fontSize: '50px', marginRight: '5px' }} />

              <p style={{ whiteSpace: 'normal' }}>
                {t('sales.searchMemberPin')}
              </p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateAutoSearch(false);
                updateSearchByPin(false);
                updateSearchByPhonenumber(true);
                updateSearchByUsername(false);
                updateSearchByFirstname(false);
                updateSearchByLastname(false);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <PhoneAndroid sx={{ fontSize: '50px', marginRight: '5px' }} />
              <p style={{ whiteSpace: 'normal' }}>
                {t('sales.searchMemberPhonenumber')}
              </p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateAutoSearch(false);
                updateSearchByPin(false);
                updateSearchByPhonenumber(false);
                updateSearchByUsername(true);
                updateSearchByFirstname(false);
                updateSearchByLastname(false);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <PersonSearchIcon sx={{ fontSize: '50px', marginRight: '5px' }} />
              <p style={{ whiteSpace: 'normal' }}>
                {t('sales.searchMemberUsername')}
              </p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateAutoSearch(false);
                updateSearchByPin(false);
                updateSearchByPhonenumber(false);
                updateSearchByUsername(false);
                updateSearchByFirstname(true);
                updateSearchByLastname(false);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <ContactMailIcon sx={{ fontSize: '45px', marginRight: '10px' }} />
              <p style={{ whiteSpace: 'normal' }}>
                {t('sales.searchByFirstname')}
              </p>
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateAutoSearch(false);
                updateSearchByPin(false);
                updateSearchByPhonenumber(false);
                updateSearchByUsername(false);
                updateSearchByFirstname(false);
                updateSearchByLastname(true);
                handleClose();
              }}
              sx={{ marginBottom: '10px' }}
            >
              <ContactEmergencyIcon
                sx={{ fontSize: '45px', marginRight: '10px' }}
              />
              <p style={{ whiteSpace: 'normal' }}>
                {t('sales.searchByLastname')}
              </p>
            </MenuItem>
          </Menu>
        </Grid>

        <Grid
          item
          xs={9}
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            id="standard-basic"
            autoComplete="off"
            label={
              searchByUsername
                ? t('sales.searchMemberUsername')
                : searchByPhonenumber
                  ? t('sales.searchMemberPhonenumber')
                  : searchByPin
                    ? t('sales.searchMemberPin')
                    : autoSearch
                      ? t('sales.autoSearch')
                      : searchByFirstname
                        ? t('sales.searchByFirstname')
                        : searchByLastname
                          ? t('sales.searchByLastname')
                          : null
            }
            variant="standard"
            sx={{
              // input label when focused
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiInput-underline:after': {
                borderBottom: '0px solid #fff',
              },
              color: 'white',
              input: {
                color: 'white',
                focused: {
                  color: 'white',
                },
                after: {
                  borderBottom: '2px solid #fff',
                },
              },
              label: {
                color: 'white',
              },
              borderBottom: '2px solid #fff',
              '&.Mui-focused': {
                color: 'white',
                borderBottom: '2px solid #fff',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {userToSearch.length === 0 ? (
                    <SearchIcon />
                  ) : (
                    <CloseIcon
                      onClick={handleResetSearch}
                      style={{ cursor: 'pointer' }} // Optional: makes the icon look clickable
                    />
                  )}
                </InputAdornment>
              ),
            }}
            inputRef={inputRef} // Attach the ref to the TextField
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              updateUserToSearch(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderSearcher;
